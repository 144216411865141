import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGIN_IN,
  LOADING, AUTH_USER_DETAILS
} from '../actions/types';
import helpers from '../../helpers'

const initialState = {
  token: localStorage.getItem(process.env.REACT_APP_TOKEN_NAME),
  isAuthenticated: !!localStorage.getItem(process.env.REACT_APP_TOKEN_NAME),
  loading: false,
  user: JSON.parse(localStorage.getItem('auth|user')),
  errorMessage: null,
}

/**
 * Reducer for authentification
 *
 * @param {state} State of this value
 * @param {action} Executing the main name of this reducer
 *
 * @return {Parameters} The different states of these reducers
 */

const auth = (state = initialState, action) => {
  const {type, payload} = action

  switch(type) {
    case LOGIN_IN:
      return {
        ...state,
        errorMessage: '',
        loading: true
      }
    case LOGIN_SUCCESS:
      helpers.setToken(payload.access_token)
      helpers.setRftk(payload.refresh_token)
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false
      }
    case AUTH_USER_DETAILS:
      helpers.setTokenUser(payload)
      return {
        ...state,
        loading: false,
        user: {...payload}
      }
    case LOGIN_FAIL:
      helpers.deleteToken()
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        errorMessage: payload
      }
    case LOGOUT:
      helpers.deleteToken()
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: null
      }
    case LOADING:
      return {
        ...state,
        errorMessage: '',
        loading: payload
      }
    default:
      return state;
  }
}

export default auth;
