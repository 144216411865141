/**
 * All types of the state management (Redux)
 */

// Authentification
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_IN = 'LOGIN_IN';
export const LOGOUT = 'LOGOUT';
export const LOADING = 'LOADING';

// Search Bar
export const GRID_VIEW = 'GRID_VIEW';
export const LIST_VIEW = 'LIST_VIEW';
export const MAP_VIEW = 'MAP_VIEW';
export const PIN_VIEW = 'PIN_VIEW';
export const INITIAL_VIEW = 'INITIAL_VIEW';
export const TOGGLE_ITEM_PIN_LIST = 'TOGGLE_ITEM_PIN_LIST';
export const TOGGLE_HELPER = 'TOGGLE_HELPER';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

// Quickcard
export const ON_NEXT = 'ON_NEXT';
export const ON_PREVIOUS = 'ON_PREVIOUS';
export const ON_FINISH = 'ON_FINISH';
export const TOGGLE_SELECT_SYMPTOM = 'TOGGLE_SELECT_SYMPTOM';
export const TOGGLE_SELECT_SUB_SYMPTOM = 'TOGGLE_SELECT_SUB_SYMPTOM';
export const QUICK_CARD_DATA_TREE = 'QUICK_CARD_DATA_TREE'

// Modal Logout
export const TOGGLE_LOGOUT_MODAL = 'TOGGLE_LOGOUT_MODAL';

// Neurologist
export const QUICK_CARD = 'QUICK_CARD';
export const SELECT_QUICK_CARD = 'SELECT_QUICK_CARD';
export const SAVE_QUICK_CARD = 'SAVE_QUICK_CARD';
export const FILTER_SYMPTOMS = 'FILTER_SYMPTOMS';
export const SELECT_SYMPTOM = 'SELECT_SYMPTOM';
export const GET_SELECTED_SYMPTOMS = 'GET_SELECTED_SYMPTOMS';
export const SELECT_THERAPY = 'SELECT_THERAPY';
export const SELECT_AUXILIARY = 'SELECT_AUXILIARY';
export const TOGGLE_SELECT_THERAPY = 'TOGGLE_SELECT_THERAPY';
export const TOGGLE_SELECT_AUXILIARY = 'TOGGLE_SELECT_AUXILIARY';
export const TOGGLE_SELECT_SYMPTOM_NEUROLOGIST = 'TOGGLE_SELECT_SYMPTOM_NEUROLOGIST';

// Patients
export const PATIENT_CREATE = 'PATIENT_CREATE';
export const PATIENT_DELETE = 'PATIENT_DELETE';
export const PATIENT_LIST = 'PATIENT_LIST';
export const GET_PATIENT_BY_ID = 'GET_PATIENT_BY_ID';

// Organisations
export const ORGANISATION_CREATE = 'ORGANISATION_CREATE';
export const ORGANISATION_DELETE = 'ORGANISATION_DELETE';
export const ORGANISATION_LIST = 'ORGANISATION_LIST';
export const GET_ORGANISATION_BY_ID = 'GET_ORGANISATION_BY_ID';

// Users
export const USER_CREATE = 'USER_CREATE';
export const USER_DELETE = 'USER_DELETE';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_LIST = 'USER_LIST';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';

// Admins
export const ADMIN_LIST = 'ADMIN_LIST'
export const ADMIN_CREATE = 'ADMIN_CREATE'
export const ADMIN_DELETE = 'ADMIN_DELETE'
export const GET_ADMIN_BY_ID = 'GET_ADMIN_BY_ID'

export const SYMPTOMS_JSON_LIST = 'SYMPTOMS_JSON_LIST';
export const AUTH_USER_DETAILS = 'AUTH_USER_DETAILS';

// feedback
export const FEEDBACK_CREATE = 'FEEDBACK_CREATE';

//user Role
export const USER_ROLE = "USER_ROLE"

//sidebar
export const SIDEBAR_OPEN = "SIDEBAR_OPEN"

// Modal Alert Data Recovery (Oct 2023)
export const OPEN_DATA_RECOVERY_MODAL = 'OPEN_DATA_RECOVERY_MODAL';
export const CLOSE_DATA_RECOVERY_MODAL = 'CLOSE_DATA_RECOVERY_MODAL';
