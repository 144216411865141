import axios from 'axios';
import helpers from '../helpers'

/**
 * The constructor for HTTP requests
 *
 * @param {Path} URL of the API Endpoint
 * @param {Payload} Parameter data of the API Endpoint
 * @param {Headers} Additional Headers parameter of the API Endpoint
 *
 * @return {Method} The method of these request
 */

class Http {

  constructor() {
    // const service = axios.create()
    const service = axios.create({ baseURL: process.env.REACT_APP_API_URL })

    const requestHandler = request => {
      if (helpers.getToken()) {
        request.headers.common['Authorization'] = helpers.getFullToken()
      }
      return request
    }
    // Attach headers on request
    service.interceptors.request.use(requestHandler)

    const responseSuccessHandler = response => {
      return response;
    };

    const responseErrorHandler = error => {
      if (error.response && error.response.status === 401) {
        helpers.deleteToken()
        window.location.href = '/login'
      }
      return Promise.reject(error);
    }
    // Attach the response and the error
    service.interceptors.response.use(
      response => responseSuccessHandler(response),
      error => responseErrorHandler(error)
    );
    this.service = service;
  }

  get(path, payload) {
    if (payload)
      return this.service.get(path, payload)

    return this.service.get(path)
  }

  post(path, payload, headers) {
    if (path === "/admin/user/morph" || path === "/oauth2/user/token") {
      // this.service.defaults.headers['Grant-Type'] = 'grant_type=impersonate&client_id=test-client&id=1&email=user@example.com'
      this.service.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      this.service.defaults.headers['Accept'] = 'application/json, text/plain'
    } else {
      if (headers) {
        this.service.defaults.headers['Content-Type'] = 'multipart/form-data'
        this.service.defaults.headers['Enctype'] = 'multipart/form-data'
        this.service.defaults.headers['Accept'] = 'multipart/form-data'
      } else {
        this.service.defaults.headers['Content-Type'] = 'application/json'
        this.service.defaults.headers['Enctype'] = 'application/json'
        this.service.defaults.headers['Accept'] = 'application/json'
      }
    }
    return this.service.post(path, payload)
  }

  put(path, payload) {
    this.service.defaults.headers['Content-Type'] = 'application/json'
    this.service.defaults.headers['Enctype'] = 'application/json'
    this.service.defaults.headers['Accept'] = 'application/json'
    return this.service.put(path, payload)
  }

  patch(path, payload) {
    this.service.defaults.headers['Content-Type'] = 'application/json'
    this.service.defaults.headers['Enctype'] = 'application/json'
    this.service.defaults.headers['Accept'] = 'application/json'
    return this.service.patch(path, payload)
  }

  delete(path) {
    return this.service.delete(path)
  }
}

export default new Http();
