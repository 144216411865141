import React from 'react';
import FromUserComponent from './User';

const FromComponent = (
  {
    symptomId,
    neurologistSymptomsSelection,
    therapistSymptomsSelection,
    assessmentSymptomsSelection,
    type
  }
) => {
  return (
    <div className="d-flex">
      <FromUserComponent
        symptoms={neurologistSymptomsSelection}
        type={type}
        symptomId={symptomId}
        userLetter="N"
      />
      <FromUserComponent
        symptoms={therapistSymptomsSelection}
        type={type}
        symptomId={symptomId}
        userLetter="T"
      />
      {assessmentSymptomsSelection.length > 0 && <FromUserComponent
        symptoms={assessmentSymptomsSelection}
        type={type}
        symptomId={symptomId}
        userLetter="A"
      />}
    </div>
  );
};

export default FromComponent;
