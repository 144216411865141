import React from 'react';

const NavigationTab = (
  {
    id,
    onClick,
    active,
    color,
    icon,
    count,
    isCommentDueDate,
    isAttachmentDueDate
  }
) => {
  const isOld = id === 2 ? isCommentDueDate : isAttachmentDueDate
  return (
    <div onClick={onClick} className={`quickcard-nav-tab ${active}`}>
      <i className={`fas ${icon} fa-2x text-white cursor-pointer ${color}`} />
      {count > 0 && <span className={`badge ${isOld ? 'badge-secondary' : 'badge-danger'} rounded-pill position-absolute`}>{count}</span>}
    </div>
  );
};

export default NavigationTab;
