import React from 'react';
import {mergeClass} from '../../../functions/function';
import {sharedBtn} from './primary-button.module.scss'
const PrimaryButton = (
  {
    children,
    className,
    variant = 'primary',
    type = 'button',
    loading,
    empty,
    ...rest
  }
) => {
  return (
    <button
      className={mergeClass(`btn btn-${variant} ${sharedBtn}`, className)}
      type={type}
      disabled={loading || empty}
      {...rest}
    >
      {loading && <i className="fas fa-spinner fa-spin" />}
      {children}
    </button>
  );
};

export default PrimaryButton;
