import http from '../http'
import { ADMINS } from '../routes/api';

/**
 * Services for admins
 *
 * All possible methods to the different API Endpoints for admins
 */

const adminService = {
  /**
   * Get all admins
   * @returns {Promise<*>}
   */
  async list() {
    try {
      const response = await http.get(ADMINS);
      return response.data
    } catch (err) {
      if (err.response.status === 403) {
        window.location.replace("/403")
      }
    }
  },
  /**
   * Get admin by ID
   *
   * @param {ID} ID of the admin user
   *
   * @returns {Promise<*>}
   */
  async getById(id) {
    try {
      const response = await http.get(`${ADMINS}/${id}`);
      return response.data
    } catch (err) {
      if (err.response.status === 404) {
        window.location.replace("/404")
      }
    }
  },
  /**
   * Save a new admin
   *
   * @param {data} Object with multiple parameters
   *
   * @returns {Promise<*>}
   */
  async store(data = {}) {
    try {
      const response = await http.post(ADMINS, data)
      return response.data
    } catch (err) {
      throw err;
    }
  },
  /**
   * Update an admin
   *
   * @param {data} Object with multiple parameters
   * @param {ID} ID of the admin user
   *
   * @returns {Promise<*>}
   */
  async update(data, adminId) {
    try {
      const response = await http.patch(`${ADMINS}/${adminId}`, data);
      return response.data
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  /**
   * Delete an admin
   *
   * @param {ID} ID of the admin user
   *
   * @returns {Promise<*>}
   */
  async delete(id) {
    try {
      return await http.delete(`${ADMINS}/${id}`)
    } catch (err) {
      throw err;
    }
  },
  /**
   * Admin impersonates user
   *
   *
   * @returns {Promise<*>}
   */
  async impersonate(data) {
    try {
      const response = await http.post(`/admin/user/morph`, data )
      return response.data
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

export default adminService
