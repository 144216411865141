import React from 'react';

const FromUserComponent = (
  {
    symptoms,
    symptomId,
    type,
    userLetter
  }
) => {
  return (
    <div className="assessment-icon">
      {symptoms.includes(symptomId) && <div className={`ja-bg-${type}`}>{userLetter}</div>}
    </div>
  );
};

export default FromUserComponent;
