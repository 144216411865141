import { TOGGLE_LOGOUT_MODAL } from './types';

/**
 * Actions for modal
 *
 * @param {dispatch} Type name
 *
 * @return {Parameters} The different states of these actions ('type' and 'payload')
 */

export const toggleLogoutModal = () => dispatch => {
  dispatch({
    type: TOGGLE_LOGOUT_MODAL
  })
}
