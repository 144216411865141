import http from '../http'
import {ORGANISATIONS, ORGANISATION} from '../routes/api';

/**
 * Services for organisation
 *
 * All possible methods to the different API Endpoints for organisation
 */

const organisationService = {
  /**
   * Get all organisations
   *
   * @returns {Promise<*>}
   */
  async list() {
    try {
      const response = await http.get(ORGANISATIONS)
      return response.data
    } catch (err) {
      throw err
    }
  },
  /**
   * Get the data of an organisation
   *
   * @param {id} ID of the organisation
   *
   * @returns {Promise<*>}
   */
  async getById(id) {
    try {
      const response = await http.get(`${ORGANISATIONS}/${id}`)
      return response.data
    } catch (error) {
      if (error.response.status === 404) {
        window.location.replace("/404")
      }
    }
  },
  /**
   * Get the data of an organisation for user profile purposes
   *
   * @returns {Promise<*>}
   */
  async getByCurrentUser() {
    try {
      const response = await http.get(`${ORGANISATION}`)
      return response.data
    } catch (error) {
      throw error;
    }
  },

  /**
   * Get the data of the current user of an organisation
   *
   * @returns {Promise<*>}
   */
  async getCurrentOrganisationUser() {
    try {
      const response = await http.get(`${ORGANISATION}/user`)
      return response.data
    } catch (error) {
      throw error;
    }
  },
  async getOrganisationUserList(organisationId) {
    try {
      const response = await http.get(`${ORGANISATIONS}/${organisationId}/users`)
      return response.data
    } catch (error) {
      throw error;
    }
  },
  async getCurrentOrganisationTherapists() {
    try {
      const response = await http.get(`${ORGANISATIONS}/manager/users`)
      return response.data
    } catch (error) {
      throw error;
    }
  },

  async assignManager(id, data) {
    try {
      const response = await http.post(`${ORGANISATIONS}/${id}/assign-manager`, data)
      return response.data
    } catch (error) {
      throw error;
    }
  },


  /**
   /**
   * Create a new organisation
   *
   * @param {data} Object with multiple parameter
   *
   * @returns {Promise<*>}
   */
  async store(data = {}) {
    try {
      const response = await http.post(ORGANISATIONS, data)
      return response.data
    } catch (err) {
      throw err;
    }
  },
  /**
   * Update an existing organisation
   *
   * @param {data} Object with multiple parameter
   * @param {organisationId} ID of the organisation
   *
   * @returns {Promise<*>}
   */
  async update(data, organisationId) {
    try {
      const response = await http.patch(`${ORGANISATIONS}/${organisationId}`, data);
      return response.data
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  /**
   * Update an existing organisation from user profile page
   *
   * @param {data} Object with multiple parameter
   * @param {organisationId} ID of the organisation
   *
   * @returns {Promise<*>}
   */
  async updateByUserProfile(data) {
    try {
      const response = await http.patch(`${ORGANISATION}`, data);
      return response.data
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  /**
   * Delete an organisation
   *
   * @param {id} ID of the organisation
   *
   * @returns {Promise<*>}
   */
  async delete(id) {
    try {
      return await http.delete(`${ORGANISATIONS}/${id}`)
    } catch (err) {
      throw err;
    }
  }
  ,
  /**
   * Delete an organisation
   *
   * @param {id} ID of the organisation
   *
   * @returns {Promise<*>}
   */
  async deleteLogo() {
    try {
      return await http.delete(`${ORGANISATION}/logo`)
    } catch (err) {
      throw err;
    }
  }
  ,
  /**
   * Delete the profile picture of the current user
   *
   * @param {id} ID of the organisation
   *
   * @returns {Promise<*>}
   */
  async deleteCurrentUserProfilePicture() {
    try {
      return await http.delete(`${ORGANISATION}/user/profile-picture`)
    } catch (err) {
      throw err;
    }
  },
  /**
   * Create Billing Export
   *
   *
   * @returns {Promise<*>}
   */
  async exportBillingMonth(data) {
    try {
      const response = await http.get(`${ORGANISATION}/${data.organizationId}/monthly-billing-report/${data.year}/${data.month}`)
      return response.data
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  /**
   * Create Billing Export
   *
   *
   * @returns {Promise<*>}
   */
  async exportBillingQuarter(data) {
    try {
      const response = await http.get(`${ORGANISATION}/${data.organizationId}/quarterly-billing-report/${data.year}/${data.quarter}`)
      return response.data
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

export default organisationService;
