import {
  ON_NEXT,
  ON_PREVIOUS,
  TOGGLE_SELECT_SYMPTOM,
  SELECT_QUICK_CARD,
  SYMPTOMS_JSON_LIST, SAVE_QUICK_CARD,
  TOGGLE_SELECT_SYMPTOM_NEUROLOGIST,
  FILTER_SYMPTOMS,
  SELECT_SYMPTOM,
  GET_SELECTED_SYMPTOMS,
  SELECT_THERAPY,
  TOGGLE_SELECT_THERAPY,
  TOGGLE_SELECT_AUXILIARY,
  SELECT_AUXILIARY, QUICK_CARD_DATA_TREE
} from './types';

/**
 * Actions for quickcard
 *
 * @param {dispatch} Type name
 *
 * @return {Parameters} The different states of these actions ('type' and 'payload')
 */

export const selectQuickCard = (quickCard) => dispatch => {
  dispatch({
    type: SELECT_QUICK_CARD,
    payload: quickCard
  })
}

export const onNext = () => dispatch => {
  dispatch({
    type: ON_NEXT
  })
}

export const onPrevious = () => dispatch => {
  dispatch({
    type: ON_PREVIOUS
  })
}

export const toggleSelectSymptom = (symptom) => dispatch => {
  dispatch({
    type: symptom.isTherapist ? TOGGLE_SELECT_SYMPTOM : TOGGLE_SELECT_SYMPTOM_NEUROLOGIST,
    payload: symptom
  })
}

export const selectSymptom = (symptom) => dispatch => {
  dispatch({
    type: SELECT_SYMPTOM,
    payload: symptom
  })
  dispatch({
    type: symptom.isTherapist ? TOGGLE_SELECT_SYMPTOM : TOGGLE_SELECT_SYMPTOM_NEUROLOGIST,
    payload: symptom
  })
}

export const selectTherapy = (therapy) => dispatch => {
  dispatch({
    type: SELECT_THERAPY,
    payload: therapy
  })
  dispatch({
    type: TOGGLE_SELECT_THERAPY,
    payload: therapy
  })
}
export const selectAuxiliary = (auxiliary) => dispatch => {
  dispatch({
    type: SELECT_AUXILIARY,
    payload: auxiliary
  })
  dispatch({
    type: TOGGLE_SELECT_AUXILIARY,
    payload: auxiliary
  })
}

export const getSymptoms = (symptoms) => dispatch => {
  dispatch({
    type: SYMPTOMS_JSON_LIST,
    payload: symptoms
  })
}

export const getSelectedSymptoms = () => dispatch => {
  dispatch({
    type: GET_SELECTED_SYMPTOMS
  })
}

export const saveQuickCard = (isSaved) => dispatch => {
  dispatch({
    type: SAVE_QUICK_CARD,
    payload: isSaved
  })
}

export const filterSymptoms = () => dispatch => {
  dispatch({
    type: FILTER_SYMPTOMS
  })
}

export const setDataTree = (data) => {
  return {
    type: QUICK_CARD_DATA_TREE,
    payload: data
  }
}
