import quickcardService from '../services/quickcardService';

/**
 * Search filter for users and quickcard
 */

export const searchOnFilter = (arr, value, userType) => {
  return arr.filter(obj => {
    const expression = Object.values(obj)
      .join('')
      .toLowerCase()
      .includes(value)
    if (userType) {
      return obj.role === userType && expression
    }
    return expression
  })
}

/**
 * Search filter for patients
 */

export const searchOnFilterPatient = (arr, value) => {
  return arr.filter(obj => {
    return Object.values(obj)
      .join('')
      .toLowerCase()
      .includes(value)
  })
}

/**
 * Search filter for admins
 */

export const searchOnFilterAdmins = (arr, value) => {
  return arr.filter(obj => {
    return Object.values(obj)
      .join('')
      .toLowerCase()
      .includes(value)
  })
}

/**
 * Handling the download
 *
 * API Endpoints in the quickcard section
 *
 * Raw data from the backend
 */

export const handleDownload = (attachment) => {
  quickcardService.downloadFile(attachment)
    .then(data => {
      let blob = new Blob([data])

      const anchor = document.createElement('a')
      anchor.download = attachment.filename
      anchor.href = URL.createObjectURL(blob)
      anchor.click()
      URL.revokeObjectURL(URL.createObjectURL(blob));
    })
}

/**
 * Text validation. It checks if it has at least one and valid character
 */
export const isValidString = (str) => {
  const trimmed = str.trim();
  const hasLetters = /[a-zA-ZÀ-ÖØ-öø-ÿǼ-ǽ]/u.test(trimmed);
  const isNotBlank = trimmed !== '';
  return hasLetters && isNotBlank;
}
