import React from 'react';
import PhysioIcon from '../../icons/physio.svg'
import SpeechDysarthriaIcon from '../../icons/speech_dysarthria.svg'
import SpeechDysphagiaIcon from '../../icons/speech_dysphagia.svg'
import SpeechSialorrhoeaIcon from '../../icons/speech_sialorrhoea.svg'
import OccupationalIcon from '../../icons/occupational.svg'
import SpeechIcon from '../../icons/speech.svg'

const AssessmentCard = (
  {
    name,
    className,
    title
  }
) => {

  const icons = {
    physio: PhysioIcon,
    speech_dysarthria: SpeechDysarthriaIcon,
    speech_sialorrhoea: SpeechSialorrhoeaIcon,
    speech_dysphagia: SpeechDysphagiaIcon,
    occupational: OccupationalIcon,
    speech: SpeechIcon
  }

  return (
    <div className={`d-flex flex-column border-radius-20 assessment-card-size ${className} cursor-pointer mx-auto`}>
      <div className={`ja-bg-${name} rounded-top-20 text-white p-3 text-center`}>
        <h5 className="mb-0">{title}</h5>
      </div>
      <div className={`d-flex justify-content-center bg-white h-100 p-5 flex-fill rounded-bottom-20 ja-border-${name}`}>
        <img src={icons[name]} alt=""/>
      </div>
    </div>
  );
};

export default AssessmentCard;
