import {CLOSE_DATA_RECOVERY_MODAL, OPEN_DATA_RECOVERY_MODAL,} from "./types";

export const openAlertDataRecovery = () => dispatch => {
  dispatch({
    type: OPEN_DATA_RECOVERY_MODAL
  })
}

export const closeAlertDataRecovery = () => dispatch => {
  dispatch({
    type: CLOSE_DATA_RECOVERY_MODAL
  })
}

