import React from 'react';

const Auxiliaries = ({symptom, propName}) => {
  return (
    <>
      {symptom.auxiliaries.filter(a => a.isChecked).length > 0 && (
        <>
          <hr/>
          <h6 className="p-2">Hilfsmittel</h6>
          <ul>
            {symptom.auxiliaries
              .filter(a => a.isChecked)
              .map(auxiliary => (<li key={auxiliary.id}>{auxiliary[propName]}</li>))}
          </ul>
        </>
      )}
    </>
  );
};

export default Auxiliaries;
