const categories = [
  {
    "name": "Spezifischer Therapiefokus",
    "symptoms": [
      {
        "name": "Brady Hypokine-se",
        "therapyModules": [
          {
            "name":"LSVT-BIG"
          },
          {
            "name": "Lokomotionstraining auf dem Laufband"
          },
          {
            "name": "Gangtraining"
          },
          {
            "name": "Ausdauertraining"
          },
          {
            "name": "Nordic Walking"
          },
          {
            "name": "Tanzen (Argentini-scher Tango)"
          },
          {
            "name": "Tai-Chi"
          }
        ],
        auxiliaries: [
          {
            "name": "Bewegungstrainer"
          },
          {
            "name": "Balancetrainer"
          }
        ]
      },
      {
        "name": "Rigor",
        "therapyModules": [
          {
            "name": "Konventionelle PT"
          },
          {
            "name": "Aktive Bewegungen (großamplitudig)"
          },
          {
            "name": "Alternierende Bewegungen mit Rhythmus"
          },
          {
            "name": "Muskelentspannung nach Jakobsen"
          },
          {
            "name": "Aktive Muskeldehnung"
          },
          {
            "name": "Anhaltende Muskeldehnung im Schlingentisch"
          },
          {
            "name": "Passive Mobilisation v. a. im Spätstadium (Kontrakturprophylaxe)"
          }
        ],
        auxiliaries: [
          {
            "name": "Bewegungstrainer"
          }
        ]
      },
      {
        "name": "Funkt. d. Bewegung (UPDRS III)",
        "therapyModules": [
          {
            "name": "Konventionelle PT"
          },
          {
            "name": "Kampfsport"
          },
          {
            "name": "Tai Chi"
          },
          {
            "name": "LSVT BIG"
          }
        ]
      },
      {
        "name": "Haltungsanomalien (Antecollis, Kamptokormie, Pisa-Syndrom)",
        "therapyModules": [
          {
            "name": "Ausdauer- und Krafttraining der betroffenen Muskulatur (Anti-Gravitationsmuskulatur dynamisch aktivieren)",
          },
          {
            "name": "Symmetrie- und Haltungsschulung (Perzeptionstraining, Korrektur vor Spiegel oder an Wand) unter Einsatz von visuellem und taktilen Feedback",
          },
          {
            "name": "Manuelle Therapie"
          },
          {
            "name": "Kinesio-Taping"
          },
          {
            "name": "Muskeldehnung der Antagonisten (rigide/dystone Muskulatur)"
          },
          {
            "name": "Gleichgewichtstraining"
          },
          {
            "name": "Gurtvorrichtung beim Lokomotionstraining auf dem Laufband zur Vertikalisierung des Oberkörpers"
          },
          {
            "name": "Intensives Training (90 Min. Einzelbehandlung, 5 x pro Woche über 4 Wochen)"
          },
          {
            "name": "Orthese für HWS"
          }
        ],
        auxiliaries: [
          {
            "name": "Rucksacktherapie"
          },
          {
            "name": "Hoher Rollator mit Unterarmschalen"
          }
        ]
      },
      {
        "name": "Gehen bzw. Gangstörungen",
        "therapyModules": [
          {
            "name": "konventionelle PT"
          },
          {
            "name": "Laufband-Training"
          },
          {
            "name": "Tanztherapie"
          },
          {
            "name": "Nordic Walking"
          },
          {
            "name": "LSVT-BIG"
          },
          {
            "name": "Tai Chi"
          },
          {
            "name": "Jogging"
          },
          {
            "name": "Cueing (verbal, akustisch, visuell, motorisch, taktil, imaginiert, individuell)"
          },
          {
            "name": "mentale Strategien (Aufmerksamkeit)"
          },
          {
            "name":  "Ausdauergehtraining"
          }
        ],
        auxiliaries: [
          {
            "name": "Bewegungstrainer"
          }
        ]
      },
      {
        "name": "Motorische Blockaden (Freezing)",
        "therapyModules": [
          {
            "name": "Cueing (verbal, akustisch, visuell, motorisch, taktil, imaginiert, individuell)"
          },
          {
            "name": "Training von Dual-Task-Situationen, Stock/ Rollator"
          },
          {
            "name": "Erlernen von Bewegungs-/ Verhaltensstrategien (Stress, Dual-Task, Stopps vermeiden; Entspannung vor Bewegungsinitiierung)"
          }
        ],
        auxiliaries: [
          {
            "name": "Anti-Freezing-Stock"
          },
          {
            "name": "Parkinson-Rollator"
          }
        ]
      },
      {
        "name": "Gleichgewichtsstörungen und Stürze",
        "therapyModules": [
          {
            "name": "Tanztherapie (Tango)"
          },
          {
            "name": "Nordic Walking"
          },
          {
            "name": "Maßnahmen zur Verbesserung der posturalen Stabilität (z.B. Tai Chi)"
          },
          {
            "name": "Laufband-Training"
          },
          {
            "name": "Training kompensatorischer Schutzschritte (Schubs-Training)"
          },
          {
            "name": "Krafttraining"
          },
          {
            "name": "Training der Propriozeption inkl. Vibrationstraining (Galileo, Qionic, stochastische Resonanztherapie)"
          },
          {
            "name": "intensives Gleichgewichtstraining (3 x pro Woche 50 Min. über 7 Wochen)"
          },
          {
            "name": "Korrektur axialer Fehlhaltungen"
          }
        ],
        auxiliaries: [
          {
            "name": "Hüftschutzhose mit Protektoren"
          },
          {
            "name": "Balance- oder Stehtrainer"
          }
        ]
      },
      {
        "name": "Kraftdefizite, Muskelschwäche",
        "therapyModules": [
          {
            "name": "konventionelle PT"
          },
          {
            "name": "Krafttraining (medizinisches Trainingstherapie, Propriozeptive Neuromuskuläre Fazilitation)"
          },
          {
            "name": "Hausaufgabenprogramm (selbstständiges Training zu Hause)"
          }
        ]
      }
    ]
  },
  {
    "name": "Allgemeiner Therapiefokus",
    "symptoms": [
      {
        "name": "Aktivitäten des täglichen Lebens (ADL)",
        "therapyModules": [
          {
            "name": "Körperlagewechsel (Sitz-Stand-Sitz, Sitz-Liegen-Sitz, Liegen-Sitzen, Umdrehen im Bett)"
          },
          {
            "name": "Treppensteigeigen"
          },
          {
            "name": "individuelles Training (Arbeit, Berufstätigkeit, Hobbys, Gartenarbeit, Aus-/Einsteigen Auto etc…)"
          }
        ],
        auxiliaries: [
          {
            "name": "Lagerungshilfen"
          },
          {
            "name": "Transferhilfen"
          }
        ]
      },
      {
        "name": "Lebensqualität",
        "therapyModules": [
          {
            "name": "QiGong"
          },
          {
            "name": "Multidisziplinäres Rehabilitationsprogramm"
          }
        ]
      }
    ]
  },
  {
    "name": "Besondere Therapiesituation",
    "symptoms": [
      {
        "name": "Tiefe Hirnstimulation",
        "therapyModules": [
          {
            "name": "Symptome im Stim-On und Stim-Off evaluieren (Verbesserung durch Umprogrammierung?)"
          },
          {
            "name": "Dopa-/ stimulationsresistente Symptome besonders trainieren"
          },
          {
            "name": "Kontraindikation: Diathermie"
          }
        ]
      },
      {
        "name": "Demenz",
        "therapyModules": [
          {
            "name": "Förderung körperlicher Aktivitäten (Spazierengehen mind. 3x30 Min./Woche)"
          },
          {
            "name": "Zerlegung von Handlungen in Untereinheiten (Chaining)"
          },
          {
            "name": "Beratung (z.B. zu sozialen Kontakten, geistiger Beschäftigung usw.)"
          },
          {
            "name": "Einbeziehung von Angehörigen/ Begleitpersonen"
          },
          {
            "name": "Aerobe Betätigungen"
          }
        ]
      }
    ]
  },
  {
    "name": "Besonderheiten bei atypischen Parkinson-Syndromen",
    "symptoms": [
      {
        "name": "Multisystematrophie vom Parkinsontyp",
        "therapyModules": [
          {
            "name": "Dehn-/ Lockerungsübungen für die stark angespannte Flexorenkette sowie Nacken- und Halsmuskulatur"
          },
          {
            "name": "Kräftigung der AntiGravitationsmuskulatur"
          },
          {
            "name": "Behandlung struktureller Defizite (flexorische Muskulatur)"
          }
        ]
      },
      {
        "name": "Multisystematrophie vom zerebellären Typ",
        "therapyModules": [
          {
            "name": "Gangtraining",
            "auxiliary": {
              "name": "Gehhilfe oder Gehwagen"
            }
          },
          {
            "name": "Koordinationsschulung und -training (wie bei zerebellärer Ataxie)"
          },
          {
            "name": "Schulung der reziproken Innervation"
          },
          {
            "name": "Präsenz und Mobilität der Füße verbessern"
          },
          {
            "name": "Stabilitätskomponenten an Rumpf"
          },
          {
            "name": "Schulter und Beckengürtel"
          }
        ]
      },
      {
        "name": "Progressive supranukleäre Paralyse",
        "therapyModules": [
          {
            "name": "Erhalten und Verbessern der noch vorhandenen Gleichgewichts-Mechanismen",
            "auxiliary": {
              "name": "Balance-Trainer und Therapiemittel"
            }
          },
          {
            "name": "Üben von Bewegungs-Übergängen: Sitz-Stand-Sitz, Sitz-Liegen-Sitz, Verlagern des Körpergewichts im Stehen auf den Vorfuß"
          },
          {
            "name": "Trainieren der Flexorenkette"
          },
          {
            "name": "Treppensteigen (visuelle Einschränkung nach unten(!))"
          },
          {
            "name": "Erlernen von Kompensationsstrategien (ventro-laterale Orientierungshilfe"
          },
          {
            "name": "bewusst flexorische Einstellung des Kopfes bei allen Lagewechseln"
          },
          {
            "name": "Gehen mit Stöcken/Rollator (ggf. gebeugte Haltung bevorzugen)"
          },
          {
            "name": "Detonisierende/schmerzreduzierende Maßnahmen (hypertone Nacken-/ Rumpfmuskulatur)"
          }
        ]
      },
      {
        "name": "Kortikobasale Degeneration",
        "therapyModules": [
          {
            "name": "Mobilisierende und detonisierende Maßnahmen an den betroffenen Extremitäten",
            "auxiliary": {
              "name": "Rollator mit Bremssystem, einhändig(!) bedienbar"
            }
          },
          {
            "name": "Kompensationen"
          },
          {
            "name": "Dynamische Stabilisierung von axialen und proximalen Körperabschnitten"
          },
          {
            "name": "Kontraktur-Prophylaxe"
          }
        ]
      }
    ]
  }
]

export default categories
