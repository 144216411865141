import React from 'react';

const TherapyModules = ({symptom, propName}) => {
  return (
    <>
      {symptom.therapy_categories.map((therapy, idx) => (
        therapy.modules.filter(t => t.isChecked).length > 0 && (
          <div key={idx}>
            <h6 className="p-2">{therapy[propName || 'Therapiemodule']}</h6>
            <ul className="mt-2" key={therapy.id + idx}>
              {therapy.modules.filter(t => t.isChecked)
                .map(module => <li key={module.id}>{module[propName]}</li>)}
            </ul>
          </div>
        )
      ))}
    </>
  );
};

export default TherapyModules;
