import jwtDecode from 'jwt-decode';

/**
 * Global helper functions
 */

const helpers = {
  /**
   * Get the token name
   */
  getToken() {
    return localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
  },
  /**
   * Get fullname of the token
   */
  getFullToken() {
    return process.env.REACT_APP_TOKEN_PREFIX + ' ' + this.getToken()
  },
  /**
   * Get the decoded token
   */
  getDecodedToken() {
    return jwtDecode(localStorage.getItem(process.env.REACT_APP_TOKEN_NAME));
  },
  /**
   * Set a new token
   *
   * @param {token} Name of the new token
   */
  setToken(token) {
    localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, token)
  },
  /**
   * Set a refresh token
   *
   * @param {token} Name of the new token
   */
  setRftk(token) {
    localStorage.setItem(process.env.REACT_APP_REFRESHTOKEN_NAME, token)
  },
  /**
   * Delete the existing token
   */
  deleteToken() {
    localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME)
    localStorage.removeItem('auth|user')
    localStorage.removeItem(process.env.REACT_APP_REFRESHTOKEN_NAME)
  },
  /**
   * Get the expiration date of this token
   */
  getTokenExpirationDate() {
    let encodedToken = this.getToken()
    if (!encodedToken) {
      return null
    }
    const token = jwtDecode(encodedToken)
    if (!token.exp) {
      return null
    }

    const date = new Date(0)
    date.setUTCSeconds(token.exp)

    return date
  },
  /**
   * Set a new user token
   *
   * @param {user} The data from a logged in user (Session token)
   */
  setTokenUser(user) {
    localStorage.setItem('auth|user', JSON.stringify(user))
  },
  /**
   * Get the token by this user (Logged in user)
   */
  getTokenUser() {
    let user = JSON.parse(localStorage.getItem('auth|user'))
    return user || {}
  },
  /**
   * Handling the errors in the form
   *
   * @param {error} Error handling and messages
   */
  formHandleError: function (error) {
    let output = [];
    if (error.response.data.message) {
      output.push('<strong>' + error.response.data.message + '</strong><br />');
    }
    if (error.response.data.errors) {
      let errors = error.response.data.errors;
      let obj;
      for (let key in errors) {
        // skip loop if the property is from prototype
        if (!errors.hasOwnProperty(key)) continue;
        obj = errors[key];
      }
      output.push('<strong>' + obj[0] + '</strong><br />');
    }

    if (error.response.data.payload) {
      let fieldErrors = error.response.data.payload;
      for (let field in fieldErrors) {
        //output.push( '<strong>' + field + '</strong><br />' ); - Removed after discussing with the team.
        if(fieldErrors.hasOwnProperty(field)) {
          fieldErrors[field].forEach(item => {
            output.push('- ' + item + '<br />');
          });
        }
      }
    }
    return output.join( '' )
  },
  /**
   * The data that a token is expired
   */
  isTokenExpired() {
    const expirationDate = this.getTokenExpirationDate()
    if (!expirationDate) {
      return true
    }
    return expirationDate < new Date()
  }
}

export default helpers;
