/**
 * API Endpoints - Main categories
 *
 * Important for all possible 'services'
 */

export const ADMIN_LOGIN_URL = '/oauth2/admin/token';
export const PATIENTS = '/patients';
export const USERS = '/users';
export const ORGANISATIONS = '/organisations';
export const ORGANISATION = '/organisation';
export const ADMINS = '/admins';
export const QUICKCARDS = '/quickcards';
