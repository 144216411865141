import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { connect , useSelector} from 'react-redux';
import PublicLayout from './public';
import LoginLayout from './login';
import MainLayout from './main';
import helpers from '../helpers'
import { AuthLoader } from "../components/LayoutComponents/loader";
import Modal from '../components/modal';
import authService from '../services/authService'; 

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
}

const IndexLayout = ({ children, loading }) => {
  const location = useLocation()
  const { pathname } = location

  /**
   * Execute the regex for layout rendering
   */
  const checkRegex = (val) => {
    const r = new RegExp(val)
    return r.test(pathname)
  }

  /**
   * Conditions for setting the 'Layout Rendering'
   */
  const getLayout = () => {
    if (['register', 'password-forget', 'new-password', "patientsurvey", "faq-all"].some(checkRegex)) {
      return 'public'
    }
    else if (/login/i.test(pathname)) {
      return 'login'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isTokenExpired = helpers.isTokenExpired()
  const expirationDate = helpers.getTokenExpirationDate()
  const isLoginLayout = getLayout() === 'login'
  const isPublicLayout = getLayout() === 'public'
  const role = useSelector(state => state.userRole.type)
  const [runRftk, setRunRftk] = useState(false) 

  /**
   * General conditions for the layout process
   */
  const BootstrappedLayout = () => {
    /**
     * Show loader when user in check authorization process, not authorized yet and not on login pages
     */
    if (loading) {
      return <AuthLoader />
    }
    /**
     * Redirect to login page if current is not login page and user not authorized
     */
    if (!isLoginLayout && isTokenExpired && !isPublicLayout) {
      return <Redirect to="/login" />
    }
    /**
     * Redirect to main dashboard when user on login page and authorized
     */
    if (isLoginLayout && !isTokenExpired) {
      return <Redirect to="/dashboard" />
    }
    /**
     * In other case render previously set layout
     */
    return <Container>{children}</Container>
  }

  useEffect(() => {
    /**
     * refresh token in the last 5 Minutes of the session timeout
     */
    if (expirationDate && role) {
      const newdate = new Date(expirationDate.getTime());
      newdate.setMinutes(expirationDate.getMinutes() - 5)
      const interval = setInterval(() => {
        if (new Date().getTime() > newdate.getTime() ) {
            setRunRftk(true)
            authService.tryRefreshToken(role).then(()=>{
              clearInterval(interval)
              setRunRftk(false)})
        }
      }, 1000)
    }
    
  }, [Container])

  return (
    <Fragment>
      <Modal />
      {BootstrappedLayout()}
    </Fragment>
  )
};

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading
})

export default connect(mapStateToProps, null)(IndexLayout);
