import {
  GET_PATIENT_BY_ID,
  PATIENT_CREATE,
  PATIENT_LIST,
  USER_LIST,
  ADMIN_LIST,
  GET_ADMIN_BY_ID,
  ADMIN_CREATE,
  PATIENT_DELETE,
  USER_DELETE, ADMIN_DELETE, GET_USER_BY_ID
} from '../actions/types';

/**
 * Validation and format for date
 *
 * @param {date} Value of a date
 *
 * @return {Parameters} Format date (If date available) or 'N/A' (If no date available)
 */

const formatDate = date => {
  let milliseconds = Date.parse(date)
  let dateObject = new Date(milliseconds)
  return dateObject.toLocaleString() === 'Invalid Date'
    ? 'N/A'
    : dateObject.toLocaleString()
}

const initialState = {
  patients: [],
  users: [],
  admins: [],
  patient: {},
  adminData: {},
  userData: {},
  salutations: [
    {
      value: 'm',
      label: 'Herr'
    },
    {
      value: 'w',
      label: 'Frau'
    },
    {
      value: 'd',
      label: 'Divers'
    }
  ],
  patientData: {
    'firstname': 'string',
    'lastname': 'string',
    'health_insurance_number': 'string',
    'birthday': '2021-05-22',
    'email': 'user@example.com',
    'username': 'string',
    'phone_number': 'string',
    'salutation': 'm',
    'title': 'string'
  },
  roles: [
    {
      value: 'neurologist',
      label: 'Neurologe'
    },
    {
      value: 'therapist_physio',
      label: 'Physiotherapeut'
    },
    {
      value: 'parkinson_nurse',
      label: 'Parkinson-Nurse'
    },
    {
      value: 'other',
      label: 'Andere'
    },
    {
      value: 'therapist_speech',
      label: 'Logopäde'
    },
    {
      value: 'therapist_occupational',
      label: 'Ergotherapeut'
    }
  ],
  colorRole: {
    neurologist: 'yellow',
    therapist_physio: 'green',
    parkinson_nurse: 'blue',
    other: 'purple'
  }
}

/**
 * Reducer for role 'user'
 *
 * @param {state} State of this value
 * @param {action} Executing the main name of this reducer
 *
 * @return {Parameters} The different states of these reducers
 */

const user = (state = initialState, {type, payload}) => {
  switch (type) {
    case PATIENT_LIST:
      return {
        ...state,
        loading: false,
        patients: payload
      }
    case USER_LIST:
      return {
        ...state,
        users: payload
      }
    case ADMIN_LIST:
      return {
        ...state,
        admins: payload
      }
    case PATIENT_CREATE:
      const patients = state.patients
      patients.push(payload)
      return {
        ...state,
        patients
      }
    case ADMIN_CREATE:
      return {
        ...state,
        adminData: {...payload}
      }
    case PATIENT_DELETE:
      const patientArr = state.patients
      const patientIndex = patientArr.findIndex(p => p.id === payload)
      if (patientIndex > -1) {
        patientArr.splice(patientIndex, 1)
      }
      return {
        ...state,
        patients: patientArr
      }
    case USER_DELETE:
      const userArr = state.users
      const userIndex = userArr.findIndex(p => p.id === payload)
      if (userIndex > -1) {
        userArr.splice(userIndex, 1)
      }
      return {
        ...state,
        users: userArr
      }
    case GET_PATIENT_BY_ID:
      payload.fullname = `${payload.firstname} ${payload.lastname}`
      payload.creation_moment = formatDate(payload.creation_moment)
      payload.registration_moment = formatDate(payload.registration_moment)
      return {
        ...state,
        patient: {...payload}
      }
    case GET_ADMIN_BY_ID:
      return {
        ...state,
        adminData: {...payload}
      }
    case GET_USER_BY_ID:
      return {
        ...state,
        userData: {...payload}
      }
    case ADMIN_DELETE:
      const adminArr = state.admins
      const adminIndex = adminArr.findIndex(p => p.id === payload)
      if (adminIndex > -1) {
        adminArr.splice(adminIndex, 1)
      }
      return {
        ...state,
        admins: adminArr
      }
    default:
      return state
  }
}

export default user;
