import React from 'react';
import classNames from 'classnames';
import styles from './style.module.scss'

export const AuthLoader = ({ spinning = true, fullScreen }) => {
  return (
    <div
      className={classNames(styles.authLoader, {
        [styles.hidden]: !spinning,
        [styles.fullScreen]: fullScreen,
      })}
    />
  );
};

export const InAppLoader = ({ spinning = true}) => {
  return (
    <div
      className={classNames(styles.inAppLoader, {
          [styles.hidden]: !spinning,
        }
      )}
    />
  );
};




