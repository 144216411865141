import {
  ORGANISATION_LIST,
  ORGANISATION_CREATE,
  GET_ORGANISATION_BY_ID,
  ORGANISATION_DELETE
} from '../actions/types';

const initialState = {
  organisations: [],
  organisationData: {
    'name': 'string',
    'street': 'string',
    'zip_code': 'string',
    'city': 'string',
    'address_additional': 'string',
    'location': {
      'latitude': 90,
      'longitude': 180
    }
  }
}

/**
 * Reducer for role 'organization'
 *
 * @param {state} State of this value
 * @param {action} Executing the main name of this reducer
 *
 * @return {Parameters} The different states of these reducers
 */

const organisation = (state = initialState, {type, payload}) => {
  switch (type) {
    case ORGANISATION_LIST:
      return {
        ...state,
        organisations: payload
      }
    case ORGANISATION_CREATE:
      const organisations = state.organisations
      organisations.push(payload)
      return {
        ...state,
        organisations
      }
    case GET_ORGANISATION_BY_ID:
      return {
        ...state,
        organisationData: {...payload}
      }
    case ORGANISATION_DELETE:
      const organisationArr = state.organisations
      const organisationIndex = organisationArr.findIndex(p => p.id === payload)
      if (organisationIndex > -1) {
        organisationArr.splice(organisationIndex, 1)
      }
      return {
        ...state,
        organisations: organisationArr
      }
    default:
      return state
  }
}

export default organisation
