import patientIcon from './icons/patient.svg'

export const AdminSidebarData = [
  {
    path: '/dashboard',
    icon: 'fas fa-home fa-2x',
    name: 'Start'
  },
  // {
  //   path: '/users',
  //   icon: 'far fa-address-book fa-2x',
  //   name: 'Profilsuche'
  // },
  {
    path: '/patients',
    icon: 'fas fa-user-injured fa-2x',
    img: patientIcon,
    name: 'Patienten'
  },
  {
    path: '/video-portal',
    icon: 'fas fa-chalkboard-teacher fa-2x',
    name: 'Schulungsbereich'
  },
  {
    path: '/download-portal',
    icon: 'fas fa-file-download fa-2x',
    name: 'Downloadbereich'
  },
  {
    path: '/admin/patients',
    icon: 'fas fa-cog fa-2x',
    name: 'Administration'
  }, {
    path: '/billing',
    icon: 'fas fa-solid fa-file-invoice fa-2x',
    name: 'Abrechnungen'
  }
]

export const NeurologistSidebarData = [
  {
    path: '/dashboard',
    icon: 'fas fa-home fa-2x',
    name: 'Start'
  },
  {
    path: '/patients',
    icon: 'fas fa-user-injured fa-2x',
    img: patientIcon,
    name: 'Patienten'
  },
  {
    path: '/quickcards',
    icon: 'far fa-clone fa-2x',
    name: 'Quickcards'
  },
  {
    path: '/profile-sharing',
    icon: 'fas fa-qrcode fa-2x',
    name: 'Profilfreigabe'
  },
  {
    path: '/users',
    icon: 'far fa-address-book fa-2x',
    name: 'Profilsuche'
  },
  {
    path: '/video-portal',
    icon: 'fas fa-chalkboard-teacher fa-2x',
    name: 'Schulungsbereich'
  },
  {
    path: '/download-portal',
    icon: 'fas fa-file-download fa-2x',
    name: 'Downloadbereich'
  },
  {
    path: '/teleconsults',
    icon: 'fa fa-television fa-2x',
    name: 'Telekonsile'
  },
]

export const TherapistSidebarData = [
  {
    path: '/dashboard',
    icon: 'fas fa-home fa-2x',
    name: 'Start'
  },
  {
    path: '/patients',
    icon: 'fas fa-user-injured fa-2x',
    img: patientIcon,
    name: 'Patienten'
  },
  {
    path: '/quickcards',
    icon: 'far fa-clone fa-2x',
    name: 'Quickcards'
  },
  {
    path: '/profile-sharing',
    icon: 'fas fa-qrcode fa-2x',
    name: 'Profilfreigabe'
  },
  {
    path: '/users',
    icon: 'far fa-address-book fa-2x',
    name: 'Profilsuche'
  },
  {
    path: '/video-portal',
    icon: 'fas fa-chalkboard-teacher fa-2x',
    name: 'Schulungsbereich'
  },
  {
    path: '/download-portal',
    icon: 'fas fa-file-download fa-2x',
    name: 'Downloadbereich'
  },
  {
    path: '/teleconsults',
    icon: 'fa fa-television fa-2x',
    name: 'Telekonsile'
  },
]
export const PatientSidebarData = [
  {
    path: '/dashboard',
    icon: 'fas fa-home fa-2x',
    name: 'Start'
  },
  {
    path: '/profile-shares',
    icon: 'far fa-clone fa-2x',
    name: 'Profilfreigaben'
  },
  {
    path: '/assessments',
    icon: 'fa fa-list fa-2x',
    name: 'Assessments'
  },
  {
    path: '/users',
    icon: 'far fa-address-book fa-2x',
    name: 'Profilsuche'
  },
  {
    path: '/access-codes',
    icon: 'fas fa-qrcode fa-2x',
    name: 'Zugangscodes'
  },
  {
    path: '/video-portal',
    icon: 'fas fa-chalkboard-teacher fa-2x',
    name: 'Schulungsbereich'
  },
  {
    path: '/download-portal',
    icon: 'fas fa-file-download fa-2x',
    name: 'Downloadbereich'
  },
  {
    path: '/teleconsults',
    icon: 'fa fa-television fa-2x',
    name: 'Videosprechstunden'
  },
]
