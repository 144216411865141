import http from '../http'

/**
 * Services for medical plans
 *
 * All possible methods to the different API Endpoints for medical plans
 */

const medicalService = {
  /**
   * Get all medical plan files by a patient
   *
   * @param {patientId} ID of the patient
   *
   * @returns {Promise<*>}
   */
  async getFiles (patientId) {
    try {
      const response = await http.get(`/patients/${patientId}/medication-plans`)
      return response.data
    } catch (err) {
      throw err
    }
  },
  /**
   * Save a new medical plan files to this patient
   *
   * @param {patientId} ID of the patient
   * @param {file} Upload an 'PDF' file
   *
   * @returns {Promise<*>}
   */
  async storeFile (patientId, file) {
    try {
      const formData = new FormData();
      formData.append("pdf_file", file);
      const response = await http.post(`/patients/${patientId}/medication-plans`,formData, true)
      return response.data
    } catch (err) {
      throw err
    }
  },
  /**
   * Download a medical plan files by a patient
   *
   * @param {patientId} ID of the patient
   * @param {medicationPlanId} ID of the medical plan
   *
   * @returns {Promise<*>}
   */
  async getDownloadFile (patientId, medicationPlanId) {
    try {
      const response = await http.get(`/patients/${patientId}/medication-plans/${medicationPlanId}/download`, {
        responseType: 'arraybuffer'
      })
      return response.data
    } catch (err) {
      throw err
    }
  },
  /**
   * Delete a medical plan files by a patient
   *
   *
   * @returns {Promise<*>}
   * @param patientId
   * @param medicationPlanId
   */
  async deleteMedicalPlan (patientId, medicationPlanId) {
    try {
      const response = await http.delete(`/patients/${patientId}/medication-plans/${medicationPlanId}`)
      return response.data
    } catch (err) {
      throw err
    }
  },
}

export default medicalService
