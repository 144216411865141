import React from 'react';
import {useHistory} from 'react-router-dom'
import { connect } from 'react-redux';
import { toggleLogoutModal } from '../../store/actions/modal';
import { doLogout } from '../../store/actions/auth';
import {modalRoot} from './styles.module.scss'
// import classNames from 'classnames'

const Index = ({open, toggleOpen, doLogout}) => {
  const history = useHistory()
  const logout = async () => {
    localStorage.removeItem("URL");
    await doLogout()
    history.push('/login')
  }
  return (
    <div>
      {open && <div className={`modal show ${modalRoot}`} id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true"
      style={{display: open && 'block'}}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-0">
            <div className="modal-header justify-content-center ja-bg-primary text-white rounded-0 py-2">
              <h3 className="modal-title">
                Ausloggen?
              </h3>
            </div>
            <div className="modal-footer border-top-0 d-flex justify-content-between logout-modal">
              <button type="button" className="btn px-5 ja-border-2 ja-text font-weight-bold" onClick={logout}>Ja</button>
              <button type="button" className="btn px-5 ja-border-2 ja-text font-weight-bold" data-dismiss="modal" onClick={toggleOpen}>Nein</button>
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
};

const mapStateToProps = state => ({
  open: state.modalApp.open
})

const mapDispatchToProps = {
  toggleOpen: toggleLogoutModal,
  doLogout
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
