import React, {useState, useEffect} from 'react';
import Comment from './components/Comment';
import {Link, useParams} from 'react-router-dom';
import quickcardService from '../../../../../services/quickcardService';
import helpers from '../../../../../helpers';
import PrimaryButton from '../../../../shared/design/buttons/primary-button';
import {Button, Modal} from 'react-bootstrap';
import SecondaryButton from '../../../../shared/design/buttons/secondary-button';
import {setFeedback} from '../../../../../store/actions/feedback';
import {useDispatch} from 'react-redux';
import moment from 'moment';
import userService from "../../../../../services/userService";
import adminService from "../../../../../services/adminService";

const CommentsComponent = ({color, updateCountNumber}) => {
  const {id} = useParams()
  const user = helpers.getTokenUser()
  const [comments, setComments] = useState([])
  // const [commentsArr, setCommentsArr] = useState([])
  const [users, setUsers] = useState([])
  const [adminUsers, setAdminUsers] = useState([])
  const [isNew, setIsNew] = useState(false)
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [commentId, setCommentId] = useState(0)
  const [showOlderComments, setShowOlderComments] = useState(false)
  const [showQuickcardId, setShowQuickcardId] = useState("")
  const [newComment, setNewComment] = useState(false)
  const [refreshingCount, setRefreshingCount] = useState(0)

  const dispatch = useDispatch()

  const mainId = window.location.href.indexOf("/quickcards/create") > -1 ? showQuickcardId : id;

  let commentArray = []

  let count = 0

  useEffect(async () => {
    setLoading(true)
    let qID = "";
    if (user.role === 'admin') {
      await adminService.list()
        .then(data => {
          setAdminUsers(data)
        })
        .catch(err => console.error(err))
    }
    await userService.list()
      .then(data => {
        setUsers(data)
      })
      .catch(err => console.error(err))

    if (window.location.href.indexOf("/quickcards/create") > -1) {
      let urlData = window.location.href;
      let latestPath = urlData.lastIndexOf('/');
      let result = urlData.substring(latestPath + 1);
      qID = result;
      setShowQuickcardId(qID)

      await quickcardService.getComments(qID)
        .then(data => {
          setComments(data)
          commentArray = data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (setLoading(false)))
    } else {
      await quickcardService.getComments(id)
        .then(data => {
          setComments(data)
          commentArray = data
          return data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (setLoading(false)))
    }

    let clonedComments = []
    commentArray.map(comment => {
      if (comment.created_by_type === 'admin') {
        if (user.role === 'admin') {
          const admCommentUser = adminUsers.find(admUser => admUser.id === comment.created_by_id)
          clonedComments.push({...comment, user: {firstname: admCommentUser.name, lastname: ''}})
        } else {
          clonedComments.push({...comment, user: {firstname: 'Vernetzungsstelle', lastname: ''}})
        }
      } else {
        const commentUser = users.find(usr => usr.id === comment.created_by_id)
        clonedComments.push({...comment, user: {firstname: commentUser?.firstname, lastname: commentUser?.lastname}})
      }
    })

    console.log({clonedComments})
    setComments(clonedComments)

    if(count < 3){
      count++
      setRefreshingCount(count)
    }
  }, [id, showQuickcardId, refreshingCount])


  const addComment = () => {
    setNewComment(true)
    setComments([...comments, {
      comment_id: 0,
      quickcard_id: mainId,
      created_at: Date.now(),
      created_by_type: user.role,
      created_by_id: user.id,
      files: [],
      user: {
        firstname: user.role === 'admin' ? user.name : user.firstname,
        lastname: user.role === 'admin' ? '' : user.lastname,
      },
      isNew: true
    }])
    setIsNew(true)
  }

  const handleCloseNew = () => {
    const index = comments.findIndex(c => c.comment_id === 0)
    if (index > -1) {
      comments.splice(index, 1)
      setComments([...comments])
    }
    setNewComment(false)
    setIsNew(false)
  }

  const handleShowOlderComments = () => {
    setShowOlderComments(!showOlderComments)
  }

  const onDeleteComment = () => {
    setLoading(true)
    setShow(true)
    quickcardService.deleteComment(commentId)
      .then(data => {
        const index = comments.findIndex(c => c.comment_id === commentId)

        let arr = [...comments]

        arr.splice(index, 1)

        setComments(arr)

        setShow(false)
      })
      .catch(err => {
        console.log(err)
        dispatch(setFeedback({
          open: true,
          success: false,
          message: "Kommentar wurde nicht gelöscht, bitte versuchen Sie es später noch einmal"
        }))
      })
      .finally(() => {
        setLoading(false)
        updateCountNumber()
        dispatch(setFeedback({open: true, success: true, message: "Ihr Kommentar wurde erfolgreich gelöscht."}))
      })
  }

  const onShowModal = (commentId) => {
    setShow(true)
    setCommentId(commentId)
  }

  const minCommentsShow = 5

  const loadComment = (comment) => {
    return (
      <Comment
        key={comment.comment_id}
        color={color}
        user={comment.user ? comment.user : []}
        comment={comment}
        isNew={isNew}
        canUserEdit={user.id === comment.created_by_id}
        quickcardId={mainId}
        onCloseNew={handleCloseNew}
        onDelete={onShowModal}
        updateCountNumber={() => updateCountNumber()}
        isAdminComment={comment.created_by_type === 'admin'}
        isCurrentAdminUser={user.role === 'admin'}
      />
    )
  }

  return (
    <div className="comment">
      {comments &&
      (comments.length <= minCommentsShow)
        ?
        (comments.map((comment) => (
          loadComment(comment)
        )))
        :
        <>
          <div className="my-4">
            {!showOlderComments &&
              <h6 className={`ja-color-${color}`}>
                Es gibt {comments.length - minCommentsShow} ältere Kommentare.
              </h6>
            }
            <div className="login-input d-flex align-items-center py-2 pl-3">
              <Link to="#" className="text-decoration-underline ja-text" onClick={handleShowOlderComments}>
                {showOlderComments
                  ? <h6>Ältere Kommentare ausblenden.</h6>
                  : <h6>Ältere Kommentare anzeigen</h6>
                }
              </Link>
            </div>
          </div>
          {showOlderComments ?
            comments.map(comment => (
              loadComment(comment)
            ))
            :
            comments.slice(minCommentsShow * (-1)).map(comment =>
              loadComment(comment)
            )
          }
        </>
      }
      {!isNew && !loading && <div className="row">
        <div className="col-md-12">
          <PrimaryButton
            onClick={addComment}>
            Kommentar hinzufügen
          </PrimaryButton>
        </div>
      </div>}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        backdrop="static"
        contentClassName="border-radius-20 ja-text"
        keyboard={false}
        animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Aktion auswählen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sind Sie sicher, dass Sie diesen Kommentar löschen wollen?
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton
            onClick={() => setShow(false)}>
            Abbrechen
          </SecondaryButton>
          <Button
            variant="danger"
            onClick={onDeleteComment}
            className="border-radius-20 px-4">
            {loading && <i className="fas fa-spinner fa-spin"/>} Löschen
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CommentsComponent;
