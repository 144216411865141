import React from 'react';
import {Link, useLocation} from 'react-router-dom';

const NavTab = () => {
  const location = useLocation()
  return (
    <nav className="navbar navbar-expand navbar-light bg-light mb-3 p-1 overflow-auto">
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto admin-navbar">
          <li className={`mr-lg-4 ${/patients/.test(location.pathname) ? 'active' : ''}`}>
            <Link
              to="/admin/patients"
              className="nav-link admin-link">
              Patienten
            </Link>
          </li>
          <li className={`mr-lg-4  ${/users/.test(location.pathname) ? 'active' : ''}`}>
            <Link
              to="/admin/users"
              className="nav-link">
              Versorger
            </Link>
          </li>
          <li className={`mr-lg-4  ${/organisations/.test(location.pathname) ? 'active' : ''}`}>
            <Link
              to="/admin/organisations"
              className="nav-link">
              Organisation
            </Link>
          </li>
          <li className={`${/admins/.test(location.pathname) ? 'active' : ''}`}>
            <Link
              to="/admin/admins"
              className="nav-link">
              Administratoren
            </Link>
          </li>
        </ul>
      </div>
    </nav>
);
};

export default NavTab;
