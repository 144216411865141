import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import styles from './style.module.scss'
import CommentsComponent from './components/comments';
import AttachmentsComponent from './components/attachments';
import MedicalPlan from './components/medicalPlan';
import PrintComponent from './components/print';
import NavigationTab from './components/NavigationTab';
import moment from 'moment';

const QuickCard = (
  {
    children,
    name,
    title,
    quickcard,
    haveDisabledTabs,
    createDate,
    updateCountNumber
  }
) => {

  const [navigationTitle, setNavigationTitle] = useState(title)
  const tabs = useSelector(state => {
    if (haveDisabledTabs) {
      return state.quickcard.navigationTabs.filter(tab => !['medical_plan', 'comments', 'comment_files', 'print'].includes(tab.key))
    } else {
      return state.quickcard.navigationTabs
    }
  })
  const [deNavigationTitle, setDeNavigationTitle] = useState({})
  const today = moment()

  const colors = [
    'physio',
    'occupational',
    'speech_dysphagia',
    'speech_dysarthria',
    'speech_sialorrhoea',
  ]

  const getColor = () => {
    return colors.includes(name)
      ? name
      : 'closed'
  }

  useEffect(() => {
    setNavigationTitle(title)
  }, [title]);
  useEffect(() => {
    const obj = {}
    tabs.forEach(tab => {
      obj[tab.key] = tab.name
    })
    setDeNavigationTitle(obj)
  }, []);

  const QuickCardSections = {
    comments: CommentsComponent,
    comment_files: AttachmentsComponent,
    medical_plan: MedicalPlan,
    print: PrintComponent
  }
  const SectionContainer = QuickCardSections[navigationTitle]
  const fontColor = navigationTitle === title ? `ja-color-${getColor()}` : 'text-white'

  return (
    <div className={`quickcard-shadow-box card-min-height ja-border-${getColor()} border-radius-20 bg-white`}>
      <div className={`${styles.header} ja-bg-${getColor()} overflow-auto overflow-customize`}>
        <div
          className={`${styles.header1} cursor-pointer text-center ${fontColor} ${navigationTitle === title && styles.active} } ${styles.fontSize}`}
          onClick={() => setNavigationTitle(title)}>
          {createDate}
        </div>
        <div className={styles.header2}>
          {tabs.map(tab => (
            <NavigationTab
              key={`tab-${tab.id}`}
              onClick={() => setNavigationTitle(tab.key)}
              color={navigationTitle === tab.key ? `ja-color-${getColor()}` : ''}
              active={navigationTitle === tab.key ? styles.active : ''}
              {...tab}
              count={(quickcard && quickcard.hasOwnProperty(`${tab.key}_count`)) ? quickcard[`${tab.key}_count`] : 0}
              isCommentDueDate={quickcard && today.diff(moment(quickcard.comments_latest_update_moment), 'week')}
              isAttachmentDueDate={quickcard && today.diff(moment(quickcard.comment_files_latest_update_moment), 'week')}
            />
          ))}
        </div>
      </div>
      <div className="card-body">
        <h4
          className={`ja-color-${getColor()} mb-3 font-weight-600 font-md`}>{deNavigationTitle[navigationTitle] || title}</h4>
        {deNavigationTitle[navigationTitle]
          ? (
            QuickCardSections.hasOwnProperty(navigationTitle)
              ? <SectionContainer color={getColor()} updateCountNumber={()=>updateCountNumber()}/>
              : <div>Inhalt wird hier angezeigt.</div>
          )
          : children
        }
      </div>
    </div>
  );
};

export default QuickCard;
