import React, {useEffect} from 'react'
import {Route, Switch, Redirect, useHistory, useLocation, useParams} from 'react-router-dom'
// import Loadable from 'react-loadable';
import loadable from '@loadable/component'
import {connect} from 'react-redux'
import {GRID_VIEW} from '../src/store/actions/types'
// import Loader from './components/LayoutComponents/loader';
import IndexLayout from './layouts'
import AdminLayout from './components/AdminComponents/layout'
import NotFound from "./pages/404"
import Forbidden from "./pages/403"
import {setLayoutView} from "./store/actions/searchBar"
import {QUESTIONNAIRE_TYPE_URL_PARAMS} from "./helpers/questionnaireParamsEnums"

// const loadable = loader =>
//   Loadable({
//     loader,
//     delay: false,
//     loading: () => <Loader />,
//   })

/**
 * Setting for the general and administration routing
 */

const routes = [
  // System Pages
  {
    path: '/video-portal',
    component: loadable(() => import('./components/videoPortal')),
    exact: true,
  },
  {
    path: '/faq-all',
    component: loadable(() => import('./components/faq/public')),
    exact: true,
  },
  {
    path: '/faq-patient',
    component: loadable(() => import('./components/faq/patients')),
    exact: true,
  },
  {
    path: '/faq-professional',
    component: loadable(() => import('./components/faq/professionals')),
    exact: true,
  },
  {
    path: '/admin/login',
    component: loadable(() => import('./components/auth/Login')),
    exact: true,
  },
  {
    path: '/login',
    component: loadable(() => import('./components/auth/UserLogin')),
    exact: true,
  },
  {
    path: '/register/:registrationId',
    component: loadable(() => import('./features/register')),
    exact: true,
  },
  {
    path: '/password-forget/:registrationId',
    component: loadable(() => import('./features/resetPassword')),
    exact: true,
  },
  {
    path: '/new-password',
    component: loadable(() => import('./features/newPassword')),
    exact: true,
  },
  {
    path: '/dashboard',
    component: loadable(() => import('./components/dashboard')),
    exact: true,
  },
  {
    path: '/quickcards/:id',
    component: loadable(() => import('./components/pages/ShowQuickCard')),
    exact: true,
  },
  {
    path: '/quickcards/:id/clarification',
    component: loadable(() => import('./components/pages/ClarificationCase')),
    exact: true,
  },
  {
    path: '/quickcards/:id/assessment',
    component: loadable(() => import('./components/pages/AssessmentResult')),
    exact: true,
  },
  {
    path: '/quickcards/:id/create-actions',
    component: loadable(() => import('./components/pages/CreateActions')),
    exact: true,
  },
  {
    path: '/quickcards',
    component: loadable(() => import('./components/pages/AllQuickCards')),
    exact: true,
  },
  {
    path: '/patients/create',
    component: loadable(() => import('./components/AdminComponents/patients/create')),
    exact: true,
  },
  {
    path: '/patients',
    component: loadable(() => import('./components/AdminComponents/patients')),
    exact: true,
  },
  {
    path: '/access-codes',
    component: loadable(() => import('./components/patient/components/access-codes')),
    exact: true,
  },
  {
    path: '/patients/:id',
    component: loadable(() => import('./components/patient/components/profile')),
    exact: true,
  },
  {
    path: '/patients/:id/questionnaires',
    component: loadable(() => import('./components/patient/components/questionnaire')),
    exact: true,
  },
  {
    path: '/patients/:id/questionnaires/:paramType',
    component: loadable(() => import('./components/patient/components/questionnaire/components/QuestionnaireManager')),
    exact: true,
  },
  {
    path: '/patients/:id/quickcards/create',
    component: loadable(() => import('./components/assessment')),
    exact: true,
  },
  {
    path: '/patients/:id/quickcards/create/:type/:quickcardId?',
    component: loadable(() => import('./components/assessment/CreateAssessment')),
    exact: true,
  },
  {
    path: '/users',
    component: loadable(() => import('./components/user')),
    exact: true,
  },
  {
    path: '/users/:id',
    component: loadable(() => import('./components/profile')),
    exact: true,
  },
  {
    path: '/users/:id/share',
    component: loadable(() => import('./components/user/components/profile')),
    exact: true,
  },
  {
    path: '/download-portal',
    component: loadable(() => import('./components/downloads')),
    exact: true,
  },
  {
    path: '/profile',
    component: loadable(() => import('./components/OwnProfile')),
    exact: true,
  },
  {
    path: '/profile/edit-profile',
    component: loadable(() => import('./components/editProfile')),
    exact: true,
  },
  {
    path: '/profile/edit-profile/organisation',
    component: loadable(() => import('./components/editOrganisationProfile')),
    exact: true,
  },
  {
    path: '/profile-sharing',
    component: loadable(() => import('./components/profileSharing')),
    exact: true,
  },
  {
    path: '/profile-shares',
    component: loadable(() => import('./components/patient/components/profile-sharing')),
    exact: true,
  },
  {
    path: '/patientsurvey',
    component: loadable(() => import('./components/questionnaire')),
    exact: true,
  },
  {
    path: '/400',
    component: loadable(() => import('./pages/400')),
    exact: true,
  },
  {
    path: '/403',
    component: loadable(() => import('./pages/403')),
    exact: true,
  },
  {
    path: '/404',
    component: loadable(() => import('./pages/404')),
    exact: true,
  },
  {
    path: '/billing',
    component: loadable(() => import('./components/AdminComponents/billing')),
    exact: true
  },
  {
    path: '/assessments',
    component: loadable(() => import('./components/patient/components/questionnaire/TilesPatient')),
    exact: true
  },
  {
    path: '/teleconsults',
    component: loadable(() => import('./components/patient/components/teleconsultation')),
    exact: true
  }
]

const adminRoutes = [
  // Administration
  {
    path: '/admin/patients',
    component: loadable(() => import('./components/AdminComponents/patients')),
    exact: true,
  },
  {
    path: '/admin/patients/create',
    component: loadable(() => import('./components/AdminComponents/patients/create')),
    exact: true,
  },
  {
    path: '/admin/patients/:patientId/edit',
    component: loadable(() => import('./components/AdminComponents/patients/create')),
    exact: true,
  },
  {
    path: '/admin/patients/:patientId/profile-sharing',
    component: loadable(() => import('./components/AdminComponents/patients/profile-sharing')),
    exact: true,
  },
  {
    path: '/admin/patients/:patientId/users',
    component: loadable(() => import('./components/user')),
    exact: true,
  },
  {
    path: '/admin/patients/:patientId/profile-sharing/:userId',
    component: loadable(() => import('./components/AdminComponents/patients/profile')),
    exact: true,
  },
  {
    path: '/admin/patients/:patientId/users/:id',
    component: loadable(() => import('./components/profile')),
    exact: true,
  },
  {
    path: '/admin/patients/:patientId/users/:id/share',
    component: loadable(() => import('./components/user/components/profile')),
    exact: true,
  },
  {
    path: '/admin/patients/:patientId/access-codes',
    component: loadable(() => import('./components/AdminComponents/patients/access-codes')),
    exact: true,
  },
  {
    path: '/admin/users',
    component: loadable(() => import('./components/AdminComponents/professionals')),
    exact: true,
  },
  {
    path: '/admin/users/create',
    component: loadable(() => import('./components/AdminComponents/professionals/create')),
    exact: true,
  },
  {
    path: '/admin/users/:id/edit',
    component: loadable(() => import('./components/AdminComponents/professionals/create')),
    exact: true,
  },
  {
    path: '/admin/organisations',
    component: loadable(() => import('./components/AdminComponents/organisations')),
    exact: true,
  },
  {
    path: '/admin/organisations/create',
    component: loadable(() => import('./components/AdminComponents/organisations/create')),
    exact: true,
  },
  {
    path: '/admin/organisations/:id/edit',
    component: loadable(() => import('./components/AdminComponents/organisations/create')),
    exact: true,
  },
  {
    path: '/admin/admins',
    component: loadable(() => import('./components/AdminComponents/admins')),
    exact: true,
  },
  {
    path: '/admin/admins/create',
    component: loadable(() => import('./components/AdminComponents/admins/create')),
    exact: true,
  },
  {
    path: '/admin/admins/:id/edit',
    component: loadable(() => import('./components/AdminComponents/admins/create')),
    exact: true,
  },
  {
    path: '/download-portal',
    component: loadable(() => import('./components/patient/components/questionnaire')),
    exact: true,
  }
]


const Router = ({setLayoutView}) => {
  const location = useLocation()
  const pathname = location.pathname;
  let url = location.pathname + location.search
  const { patientId } = useParams();
  const {newTeleconsult} = QUESTIONNAIRE_TYPE_URL_PARAMS
  const handleTab = (event) => {
    if (event.keyCode === 9) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (url === "/faq-all" || url === "/register" || url === "/register/user" || url === "/register/patient" || url === "/password-forget/patient" || url === "/password-forget/user" || url === "/new-password") {
      localStorage.setItem("URL", "/dashboard")
    } else if (url !== "/login") {
      localStorage.setItem("URL", url)
    } else if (url === "/") {
      localStorage.setItem("URL", "/dashboard")
    }
    if (url.includes(`questionnaires`)) {
      setLayoutView(GRID_VIEW)
      document.addEventListener('keydown', handleTab);
    }
    if (url.includes(`video-portal`)) {
      setLayoutView(GRID_VIEW)
    }
    if (url.includes(`download-portal`)) {
      setLayoutView(GRID_VIEW)
    }
    if (url.includes(`users`)) {
      setLayoutView(GRID_VIEW)
    }

    return () => {
      if (
        pathname !== `/patients/${patientId}/questionnaires/${newTeleconsult}` &&
        pathname !== '/teleconsults'
      ) {
        localStorage.removeItem('selectedOption');
      }

      if (url.includes(`questionnaires`)) {
        document.removeEventListener('keydown', handleTab);
      }
    };
  })
  return (
    <IndexLayout>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login"/>}/>
        {routes.map(route => (
          <Route
            path={route.path}
            component={route.component}
            key={route.path}
            exact={route.exact}
          />
        ))}
        <Route path="/admin">
          <AdminLayout>
            {adminRoutes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
          </AdminLayout>
        </Route>
        <Route path="*" component={NotFound}/>
      </Switch>
    </IndexLayout>
  )
}

const mapDispatchToProps = {
  setLayoutView
}

export default connect(null, mapDispatchToProps)(Router)
