import {
  TOGGLE_ITEM_PIN_LIST,
  TOGGLE_HELPER,
  TOGGLE_SIDEBAR
} from './types';

/**
 * Actions for searchBar
 *
 * @param {dispatch} Type name
 *
 * @return {Parameters} The different states of these actions ('type' and 'payload')
 */

export const setLayoutView = (view) => dispatch => {
  dispatch({
    type: view,
    payload: {layoutView: view}
  })
}

export const toggleItemPinList = (item) => dispatch => {
  dispatch({
    type: TOGGLE_ITEM_PIN_LIST,
    payload: item
  })
}

export const toggleHelper = () => dispatch => {
  dispatch({
    type: TOGGLE_HELPER
  })
}

export const toggleSidebar = () => {
  return {
    type: TOGGLE_SIDEBAR
  }
}
