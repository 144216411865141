import {
  GRID_VIEW,
  LIST_VIEW,
  MAP_VIEW,
  PIN_VIEW,
  TOGGLE_ITEM_PIN_LIST,
  TOGGLE_HELPER,
  TOGGLE_SIDEBAR
} from '../actions/types';

const initialState = {
  layoutView: GRID_VIEW, // we can have some initial grid views
  opened: false,
  showSidebar: true,
  cardList: [
    {
      name: 'ja-bg-blue',
      isPinned: false,
      color: 'blue'
    },
    {
      name: 'ja-bg-orange',
      isPinned: false,
      color: 'orange'
    },
    {
      name: 'ja-bg-yellow',
      isPinned: false,
      color: 'yellow'
    },
    {
      name: 'ja-bg-green',
      isPinned: false,
      color: 'green'
    }
  ],
  pinCardList: [],
  roles: [
    {
      id: 'neurologist',
      name: 'Neurologe'
    },
    {
      id: 'therapist_physio',
      name: 'Physiotherapeut'
    },
    {
      id: 'therapist_speech',
      name: 'Logopäde'
    },
    {
      id: 'therapist_occupational',
      name: 'Ergotherapeut'
    },
    {
      id: 'parkinson_nurse',
      name: 'Parkinson-Nurse'
    },
    // {
    //   id: 'patient',
    //   name: 'Patient'
    // },
    // {
    //   id: 'assessment',
    //   name: 'Assessment'
    // },
    // {
    //   id: 'other',
    //   name: 'Andere'
    // },
  ]
}

/**
 * Reducer for searchBar
 *
 * @param {state} State of this value
 * @param {action} Executing the main name of this reducer
 *
 * @return {Parameters} The different states of these reducers
 */

const searchBar = (state = initialState, action) => {
  const {type, payload} = action

  switch(type) {
    case GRID_VIEW:
      return {
        ...state,
        ...payload
      }
    case LIST_VIEW:
      return {
        ...state,
        ...payload
      }
    case MAP_VIEW:
      return {
        ...state,
        ...payload
      }
    case PIN_VIEW:
      return {
        ...state,
        ...payload
      }
    case TOGGLE_ITEM_PIN_LIST:
      const {cardList, pinCardList} = state
      const index = pinCardList.findIndex(item => item.name === payload)
      const item = cardList.find(c => c.name === payload)
      if (index > -1) {
        pinCardList.splice(index, 1)
      } else {
        pinCardList.push({
          name: payload,
          isPinned: true
        })
      }
      item.isPinned = !item.isPinned
      return {
        ...state,
        cardList: [...cardList],
        pinCardList: [...pinCardList]
      }
    case TOGGLE_HELPER:
      return {
        ...state,
        opened: !state.opened
      }
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        showSidebar: !state.showSidebar
      }
    default:
      return state;
  }
}

export default searchBar;
