import {
  ON_NEXT,
  ON_PREVIOUS,
  SELECT_QUICK_CARD,
  TOGGLE_SELECT_SYMPTOM,
  SYMPTOMS_JSON_LIST,
  SAVE_QUICK_CARD,
  TOGGLE_SELECT_SYMPTOM_NEUROLOGIST,
  FILTER_SYMPTOMS, SELECT_SYMPTOM,
  GET_SELECTED_SYMPTOMS,
  SELECT_THERAPY,
  TOGGLE_SELECT_THERAPY,
  SELECT_AUXILIARY,
  TOGGLE_SELECT_AUXILIARY,
  QUICK_CARD_DATA_TREE
} from '../actions/types';

import categories from '../../mock/categories';
import SpeechQuickCardSubtype from "../../components/assessment/SpeechQuickCardSubtype";

const initialState = {
  dataTree: [],
  lang: 'de',
  cards: [
    {
      id: 1,
      name: 'physio',
      title: 'Physiotherapie'
    },
    {
      id: 2,
      name: 'occupational',
      title: 'Ergotherapie'
    },
    /*   {
         id: 3,
         name: 'speech',
         title: 'Logopädie'
       },*/
    {
      id: 3,
      name: 'speech_dysphagia',
      title: 'Dysphagie'
    },
    {
      id: 4,
      name: 'speech_dysarthria',
      title: 'Dysarthrie'
    },
    {
      id: 5,
      name: 'speech_sialorrhoea',
      title: 'Sialorrhoe'
    },
  ],

/*
  speechQuickCardSubtypes: [
    {
      id: 1,
      name: 'speech_dysphagia',
      title: 'Dysphagie',
      speechSymptomCategories: [
        {
          id: 1,
          name: 'speech_dysphagia_c1',
          title: 'orale Phase'
        },
        {
          id: 2,
          name: 'speech_dysphagia_c2',
          title: 'oropharyngeale Phase'
        },
        {
          id: 3,
          name: 'speech_dysphagia_c3',
          title: 'pharyngeale Phase'
        },
        {
          id: 4,
          name: 'speech_dysphagia_c4',
          title: 'ösophagale Phase'
        },
      ],
    },
    {
      id: 2,
      name: 'speech_dysarthria',
      title: 'Dysarthrie',
      speechSymptomCategories: [
        {
          id: 1,
          name: 'speech_dysarthria_c1',
          title: 'orale'
        },
        {
          id: 2,
          name: 'speech_dysarthria_c2',
          title: 'oropharyngeale'
        },
        {
          id: 3,
          name: 'speech_dysarthria_c3',
          title: 'pharyngeale'
        },
        {
          id: 4,
          name: 'speech_dysarthria_c4',
          title: 'ösophagale'
        },
      ],
    },
    {
      id: 3,
      name: 'speech_sialorrhoea',
      title: 'Sialorrhoe',
      speechSymptomCategories: [
        {
          id: 1,
          name: 'speech_sialorrhoea_c1',
          title: 'orale rfr'
        },
        {
          id: 2,
          name: 'speech_sialorrhoea_c2',
          title: 'orophafrewfw'
        },
        {
          id: 3,
          name: 'speech_sialorrhoea_c3',
          title: 'pharynwefwgtrwedfwe'
        },
        {
          id: 4,
          name: 'speech_sialorrhoea_c4',
          title: 'ösophagfewwdfewfale'
        },
      ],
    },
    {
      id: 4,
      name: 'speech_dysphagia_sialorrhoea',
      title: 'Dysphagie + Sialorrhoe'
    },
    {
      id: 5,
      name: 'speech_dysarthria_sialorrhoea',
      title: 'Dysarthrie + Sialorrhoe'
    },
  ],

  speechSymptomCategories: [
    {
      id: 'speech_dysphagia',
      name: 'speech_dysphagia_c1',
      title: 'orale Phase'
    },
    {
      id: 'speech_dysphagia',
      name: 'speech_dysphagia_c2',
      title: 'oropharyngeale Phase'
    },
    {
      id: 'speech_dysphagia',
      name: 'speech_dysphagia_c3',
      title: 'pharyngeale Phase'
    },
    {
      id: 'speech_dysphagia',
      name: 'speech_dysphagia_c4',
      title: 'ösophagale Phase'
    },
    {
      id: 'speech_dysarthria',
      name: 'speech_dysarthria_c1',
      title: 'hhforale'
    },
    {
      id: 'speech_dysarthria',
      name: 'speech_dysarthria_c2',
      title: 'ghgbgforopharyngeale'
    },
    {
      id: 'speech_dysarthria',
      name: 'speech_dysarthria_c3',
      title: 'nnnnfgbfpharyngeale'
    },
    {
      id: 'speech_dysarthria',
      name: 'speech_dysarthria_c4',
      title: 'gggggösophagale'
    },
  ],
*/

  quickCards: [
    {
      id: 111,
      color: 'green',
      title: 'Physiotherapie'
    },
    {
      id: 222,
      color: 'yellow',
      title: 'Ergotherapie'
    },
    {
      id: 333,
      color: 'blue',
      title: 'Logopädie – Dysphagie'
    },
    {
      id: 444,
      color: 'blue',
      title: 'Logopädie – Dysarthrie'
    },
    {
      id: 555,
      color: 'blue',
      title: 'Logopädie – Sialorrhoe'
    }
  ],
  navigationTabs: [
    {
      id: 1,
      icon: 'fa-notes-medical',
      name: 'Medikationspläne',
      key: 'medical_plan',
      count: 0
    },
    {
      id: 2,
      icon: 'fa-comments',
      name: 'Kommentare',
      key: 'comments',
      count: 0
    },
    {
      id: 3,
      icon: 'fa-paperclip',
      name: 'Anhänge',
      key: 'comment_files',
      count: 0
    },
    {
      id: 4,
      icon: 'fa-print',
      name: 'Drucken',
      key: 'print',
      count: 0
    },
    // {
    //   id: 5,
    //   icon: 'fa-info-circle',
    //   name: 'Info',
    //   key: 'info',
    //   count: 0
    // }
  ],
  neurologist: {
    saved: false
  },
  therapist: {
    saved: false,
    assigned: false
  },
  isAborted: false,
  selectedQuickCard: {
    color: '',
    title: ''
  },
  selectedSymptoms: [],
  selectedTherapies: [],
  selectedAuxiliaries: [],
  selectedNeurologistSymptoms: [],
  filteredSymptoms: [],
  checkedValues: [],
  categoryNames: [],
  mySymptoms: categories
}

/**
 * Reducer for quickcard
 *
 * @param {state} State of this value
 * @param {action} Executing the main name of this reducer
 *
 * @return {Parameters} The different states of these reducers
 */

const quickcard = (state = initialState, action) => {
  const {type, payload} = action
  const {mySymptoms} = state

  switch (type) {
    case SELECT_QUICK_CARD:
      return {
        ...state,
        selectedQuickCard: {...payload}
      }
    case ON_NEXT:
      let nextStep = state.step
      if (nextStep < 4) nextStep += 1
      return {
        ...state,
        step: nextStep
      }
    case ON_PREVIOUS:
      let prevStep = state.step
      if (prevStep > 1) prevStep -= 1
      return {
        ...state,
        step: prevStep
      }
    case TOGGLE_SELECT_SYMPTOM:
      const {selectedSymptoms} = state
      const foundIndex = selectedSymptoms.findIndex(s => s && (s.name === payload.symptom.name))
      if (foundIndex > -1) {
        selectedSymptoms.splice(foundIndex, 1)
      } else {
        selectedSymptoms.push({...payload.symptom, category: payload.category})
      }
      return {
        ...state,
        selectedSymptoms: [...selectedSymptoms]
      }
    case TOGGLE_SELECT_SYMPTOM_NEUROLOGIST:
      const {selectedNeurologistSymptoms} = state
      const fIndex = selectedNeurologistSymptoms.findIndex(s => s && (s.name === payload.symptom.name))

      if (fIndex > -1) {
        selectedNeurologistSymptoms.splice(fIndex, 1)
      } else {
        selectedNeurologistSymptoms.push({...payload.symptom, category: payload.category})
      }
      return {
        ...state,
        selectedNeurologistSymptoms: [...selectedNeurologistSymptoms]
      }
    case SYMPTOMS_JSON_LIST:
      return {
        ...state,
        mySymptoms: payload
      }
    case FILTER_SYMPTOMS:
      return {
        ...state
      }
    case SELECT_SYMPTOM:
      let prop = payload.isTherapist ? 'checked' : 'selected'
      payload.symptom[prop] = !payload.symptom.hasOwnProperty(prop)
        ? true
        : !payload.symptom[prop]
      return {
        ...state,
        mySymptoms: [...mySymptoms]
      }
    case SELECT_THERAPY:
      payload['checked'] = !payload.hasOwnProperty('checked')
        ? true
        : !payload['checked']
      return {
        ...state,
        mySymptoms: [...mySymptoms]
      }
    case SELECT_AUXILIARY:
      payload['checked'] = !payload.hasOwnProperty('checked')
        ? true
        : !payload['checked']
      return {
        ...state,
        mySymptoms: [...mySymptoms]
      }
    case TOGGLE_SELECT_THERAPY:
      const {selectedTherapies} = state
      const therapyIndex = selectedTherapies.findIndex(s => s && (s.name === payload.name))
      if (therapyIndex > -1) {
        selectedTherapies.splice(therapyIndex, 1)
      } else {
        selectedTherapies.push(payload)
      }
      return {
        ...state,
        selectedTherapies: [...selectedTherapies]
      }
    case TOGGLE_SELECT_AUXILIARY:
      const {selectedAuxiliaries} = state
      const auxiliaryIndex = selectedAuxiliaries.findIndex(s => s && (s.name === payload.name))
      if (auxiliaryIndex > -1) {
        selectedAuxiliaries.splice(auxiliaryIndex, 1)
      } else {
        selectedAuxiliaries.push(payload)
      }
      return {
        ...state,
        selectedAuxiliaries: [...selectedAuxiliaries]
      }
    case GET_SELECTED_SYMPTOMS:
      const arr = state.mySymptoms.map(c => {
        return {
          name: c.name,
          symptoms: c.symptoms.filter(s => s.hasOwnProperty('selected') && s.selected === true)
        }
      }).filter(cat => cat.symptoms.length)
      return {
        ...state,
        selectedSymptoms: arr
      }
    case SAVE_QUICK_CARD:
      return {
        ...state,
        neurologist: {
          saved: payload
        }
      }
    case QUICK_CARD_DATA_TREE:
      return {
        ...state,
        dataTree: payload
      }
    default:
      return state;
  }
}

export default quickcard;
