import {
  AUTH_USER_DETAILS,
  LOADING,
  LOGIN_FAIL,
  LOGIN_IN,
  LOGIN_SUCCESS,
  LOGOUT
} from './types';
import authService from '../../services/authService'

/**
 * Actions for authentification
 *
 * @param {dispatch} Type name
 *
 * @return {Parameters} The different states of these actions ('type' and 'payload')
 */

export const doLogin = (credentials) => dispatch => {
  dispatch({
    type: LOGIN_IN
  })
  return new Promise((resolve, reject) => {
    authService.login(credentials)
      .then(({data}) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: data
        })
        resolve(data)
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAIL,
          payload: err.message
        })
        reject(err)
      })
  })
}

export const successLogin = (data) => dispatch =>{
  dispatch({
    type: LOGIN_SUCCESS,
    payload: data
  })
}

export const failedLogin = (data) => dispatch =>{
  dispatch({
    type: LOGIN_FAIL,
    payload: data
  })
}

export const doLoginUser = (payload) => dispatch => {
  dispatch({
    type: LOGIN_IN
  })
  return new Promise((resolve, reject) => {
    authService.loginUser(payload.credentials, payload.registrationId)
      .then(({data}) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: data
        })
        resolve(data)
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAIL,
          payload: err.message
        })
        reject(err)
      })
  })
}

export const setAuthDetails = (data) => dispatch => {
  dispatch({
    type: LOGIN_IN
  })
  return new Promise(resolve => {
    resolve(dispatch({
      type: AUTH_USER_DETAILS,
      payload: data
    }))
  })
}

export const doLogout = () => dispatch => {
  dispatch({
    type: LOGOUT
  })
}

export const setLoading = (loading) => dispatch => {
  return dispatch({
    type: LOADING,
    payload: loading
  })
}

export const setLoadingNew = (loading) => {
  return {
    type: LOADING,
    payload: loading
  }
}
