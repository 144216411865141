import React, { useState, useEffect, useRef } from 'react';
import UploadCard from './uploads';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import ShowQuickcardPage from './components/quickcardOverview';


const Print = () => {
    const componentRef = useRef()
    const [commentChecked, setCommentChecked] = useState(false)
    const handleClick = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
        @media all {
            .page-break {
              display: none;
            }
          }
          
          @media print {
            html, body {
              height: initial !important;
              overflow: initial !important;
              -webkit-print-color-adjust: exact;
            }
          }
          
          @media print {
            .page-break {
              margin-top: 1rem;
              display: block;
              page-break-before: auto;
            }
          }
          
          @page {
            size: auto;
            margin: 20mm;
          }
      `
    })
    const handleCheck = (e) => {
        setCommentChecked(e ? true : false)
    }
    return (
        <div>
            <h5 className="ja-text-gray">
              Hier können Sie einen Export dieser Quickcard erstellen.
            </h5>
            <ShowQuickcardPage hidden={true} componentRef={componentRef} includeComments={commentChecked}/>
            <UploadCard
                name={'Export erstellen'}
            >
                <div className="text-center py-2"
                >
                    <i className="fas fa-download fa-5x cursor-pointer medical-plan-icon" onClick={() => handleClick()}></i>
                </div>
            </UploadCard>
            <label htmlFor="check" className="d-flex align-items-center cursor-pointer justify-content-center">
                <div className="mr-4">
                    <input
                        type="checkbox"
                        className="checkbox-round"
                        id="check"
                    onChange={e => handleCheck(e.target.checked)}
                    />
                </div>
                <div className="ja-text-gray pr-1 font-size-18">Kommentare in den Export aufnehmen</div>
            </label>
        </div>
    )

}

export default Print;
