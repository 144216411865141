import React, { useState, useEffect } from 'react';
import Comment from '../../../comments/components/Comment';
import { useParams } from 'react-router-dom';
import quickcardService from '../../../../../../../services/quickcardService';
import userService from '../../../../../../../services/userService';

const CommentsComponent = ({ color, updateCountNumber }) => {
  const { id } = useParams()
  const [comments, setComments] = useState([])
  const [commentsArr, setCommentsArr] = useState([])
  const [users, setUsers] = useState([])
  const [showQuickcardId, setShowQuickcardId] = useState("")


  const mainId = window.location.href.indexOf("/quickcards/create") > -1 ? showQuickcardId : id;

  useEffect(() => {
    let qID = "";
    userService.list().then(data => setUsers(data)).catch(err => console.error(err))

    if (window.location.href.indexOf("/quickcards/create") > -1) {
      let urlData = window.location.href;
      let latestPath = urlData.lastIndexOf('/');
      let result = urlData.substring(latestPath + 1);
      qID = result;
      setShowQuickcardId(qID)

      quickcardService.getComments(qID)
        .then(data => {
          setComments(data)
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      quickcardService.getComments(id)
        .then(data => {
          setComments(data)
          return data
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [id, showQuickcardId])

  useEffect(() => {
    let clonedComments = []
    comments.map(comment => {
      users.map(u => {
        if (comment.created_by_id === u.id) {
          clonedComments.push({
            ...comment,
            user: {
              firstname: u.firstname,
              lastname: u.lastname
            }
          })
        }
      })
    })
    setCommentsArr(clonedComments)
  },[comments, users])

  const loadComment = (comment) => {
      return (
        <Comment
          key={comment.comment_id}
          color={color}
          user={comment.user ? comment.user : []}
          comment={comment}
          canUserEdit={false}
          quickcardId={mainId}
          updateCountNumber={() => updateCountNumber()}
        />
      )
  }

  return (
    <div className="comment mt-5">
      {commentsArr &&
          <>
            {
              commentsArr.map(comment => (
                loadComment(comment)
              ))
            }
          </>
      }
    </div>
  );
}

export default CommentsComponent;
