import React, {useEffect, useState, useRef} from 'react'
import {connect, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import quickcardService from '../../../../../../../services/quickcardService'
import QuickCard from '../../../..'
import QuickCardCategory from '../../../category'
import userService from '../../../../../../../services/userService'
import helpers from '../../../../../../../helpers'
import StatusBar from '../../../../../../shared/design/statusBar'
import FromComponent from '../../../../../../assessment/components/from'
import TherapyModules from '../../../../../../assessment/components/therapy-modules'
import Auxiliaries from '../../../../../../assessment/components/auxiliaries'
import organisationService from '../../../../../../../services/organisationService'
import CommentsComponent from '../quickcardComments'

const ShowQuickcardPage = ({lang, quickcards, hidden, componentRef, includeComments}) => {
  let {id} = useParams()
  const [quickcard, setQuickcard] = useState({})
  const [found, setFound] = useState({})
  const [canEdit, setCanEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [selectedSymptoms, setSelectedSymptoms] = useState([])
  const [users, setUsers] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [updateDate, setUpdateDate] = useState(null)
  const [createDate, setCreateDate] = useState(null)
  const [isAborted, setIsAborted] = useState(false)
  const [hasAssessment, setHasAssessment] = useState(false)
  const [patient, setPatient] = useState()
  const [neurologist, setNeurologist] = useState()
  const [therapist, setTherapist] = useState()
  const [hasAssessmentUser, setHasAssessmentUser] = useState(null)
  const [options, setOptions] = useState([])
  const [updatestate, setUpdateState] = useState(false)
  const user = helpers.getTokenUser()

  const userData = useSelector(state => state.auth.user)

  const filterUsers = (q) => {
    userService.list()
      .then(data => {
        setAllUsers(data)
        const filterUser = (d) => {
          const regex = new RegExp(q.type.split('_')[0])
          return regex.test(d.role)
        }
        const arr = data.filter(filterUser)
        setUsers(arr)
      })
      .catch(err => {
        console.log('ERROR. Please check the code and try again', err)
      })
  }

  const formatDate = (date) => {
    const options = {year: 'numeric', month: '2-digit', day: '2-digit'}

    return new Date(date).toLocaleString("de-DE", options).slice(0, 10)
  }

  useEffect(() => {
    let str = localStorage.getItem(`URL`)
    let shortStr = str.slice(20)
    let quickcardID = str.length > 20 ? shortStr.replace(/^\D+/g, '') : id
    quickcardService.getQuickCardById(quickcardID)
      .then(data => {
        setPatient(data.patient)
        setQuickcard(data)
        setNeurologist(data.neurologist)
        setTherapist(data.therapist)
        setFound(data)
        filterUsers(data)
        setIsAborted(data.state === 'aborted' || data.state === 'closed')
        setCreateDate(formatDate(data.creation_moment))
        setUpdateDate(formatDate(data.update_moment))
        setHasAssessment(data.assessment_symptom_selection.length > 0)
        setHasAssessmentUser(data.assessment_user)
        let chosen = quickcards.find(q => q.id === data.type) || quickcards[0]
        if (chosen) {
          const sSymptoms = [...data.neurologist_symptom_selection, ...data.therapist_symptom_selection, ...data.assessment_symptom_selection]
          setSelectedSymptoms(sSymptoms)
          setQuickcard(chosen)
          const selectedTherapyModules = [...data.therapist_therapy_module_selection, ...data.assessment_therapy_module_selection]
          const selectedAuxiliaries = [...data.therapist_auxiliary_selection, ...data.assessment_auxiliary_selection]
          const arr = chosen.symptom_categories.map(c => ({
            ...c,
            symptoms: c.symptoms.map(s => {
              const therapies = s.therapy_categories.map(sc => {
                return {
                  ...sc,
                  modules: sc.modules.map(m => {
                    return {
                      ...m,
                      isChecked: selectedTherapyModules.includes(m.id)
                    }
                  })
                }
              })
              return {
                ...s,
                isChecked: sSymptoms.includes(s.id),
                therapy_categories: therapies,
                auxiliaries: s.auxiliaries.map(a => ({
                  ...a,
                  isChecked: selectedAuxiliaries.includes(a.id)
                }))
              }
            })
          }))
          setCategories(arr)
        }
      })
      .catch(err => {
        console.log('ERROR. Please check the code and try again', err)
      })
  }, [id, quickcards, updatestate])

  useEffect(() => {
    let setoptions = []
    organisationService.list().then(data => {
      setLoading(true)
      user.role === 'admin'
        ?
        allUsers.map(u => {
          let found = data.find(o => o.id === u.organisation_id)
          setoptions.push({label: `${u.firstname}  ${u.lastname} ${found.name ? ' - ' + found.name : ""}`, value: u.id})
        })
        : users.map(u => {
          let found = data.find(o => o.id === u.organisation_id)
          setoptions.push({label: `${u.firstname}  ${u.lastname} ${found.name ? ' - ' + found.name : ""}`, value: u.id})
        })
    }).finally(() => {
      setoptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setOptions(setoptions)
      setLoading(false)
    })
    let canEdit = false
    if (found.neurologist) {
      if (found.neurologist.id === user.id) {
        canEdit = true
      }
      if (found.neurologist.id !== user.id) {
        canEdit = false
      }

      if (found.state == "assessment_external") {
        if (found.assessment_user === null) {
          canEdit = false
        } else if (found.assessment_user.id === user.id) {
          canEdit = true
        } else if (found.assessment_user.id !== user.id) {
          canEdit = false
        }
      }
    } else if (found.neurologist && !found.therapist) {
      if (user.role.includes("therapist") && user.role.includes(found.type)) {
        canEdit = false
      } else {
        canEdit = true
      }
    } else if (found.neurologist && !found.therapist && found.neurologist.id === user.id) {
      canEdit = true
    } else if ((found.neurologist && found.neurologist.id !== user.id) && !found.therapist) {
      canEdit = false
    } else if (found.neurologist && found.therapist && found.therapist.id === user.id) {
      canEdit = true
    } /*else if (found.therapist && found.neurologist.id === userData.id){
      canEdit = true
    }*/
    if (found.therapist) {
      if (found.therapist.id === user.id) {
        canEdit = true
      }
      if (found.therapist.id !== user.id) {
        canEdit = false
      }
      if (found.state == "assessment_external") {
        if (found.assessment_user === null) {
          canEdit = false
        } else if (found.assessment_user.id === user.id) {
          canEdit = true
        } else if (found.assessment_user.id !== user.id) {
          canEdit = false
        }
      }
      if (user.role && found.neurologist.id === userData.id) {
        canEdit = true
      }
    }
    if (user.role && found.neurologist && !found.therapist && user.role.includes("therapist") && user.role.includes(found.type)) {
      canEdit = true
    }
    if (user.role === "admin") {
      if (found.state === "assessment_neurologist" || found.state === "assessment_therapist" || found.state === "consensus" || found.state === "assessment_external" || found.state === "therapy" || found.state === "consensus_completed") {
        canEdit = true
      } else {
        canEdit = false
      }
    }

    setCanEdit(canEdit)
  }, [found, user.role, allUsers, users])

  const updateCountNumberFunc = () => {
    setUpdateState(!updatestate)
  }

  let propName = `name_${lang}`

  const hasConflict = (symptom) => {
    if (user.assessment_role === true && ['assessment_external', 'consensus_completed', 'therapy'].includes(found.state))
      return `ja-bg-${found.type} ja-border-${found.type}`
    else if (
      found.therapist_symptom_selection.length > 0 &&
      ((found.neurologist_symptom_selection.includes(symptom.id) && !found.therapist_symptom_selection.includes(symptom.id))
        || (!found.neurologist_symptom_selection.includes(symptom.id) && found.therapist_symptom_selection.includes(symptom.id)))
    ) {
      return 'ja-bg-warning ja-border-warning'
    }
    return `ja-bg-${found.type} ja-border-${found.type}`
  }

  return (
    <>
      <div className={`p-3 ${hidden ? "d-none" : ""}`}>
        <div className={`p-3`} ref={componentRef}>
          <StatusBar
            found={found}
            patient={patient}
            therapist={therapist}
            neurologist={neurologist}
            createDate={createDate}
            isAborted={isAborted}
            updateDate={updateDate}
            hasAssessmentUser={hasAssessmentUser}
          />
          {quickcard && Object.keys(quickcard).length > 0 && <div className="row mt-3">
            <div className="col-lg-12">
              {quickcard && <QuickCard
                name={found.type}
                createDate={quickcard[propName]}
                quickcard={found}
                title="Quickcard Übersicht"
                updateCountNumber={() => updateCountNumberFunc()}
              >
                {categories.filter(c => c.symptoms.some(s => s.isChecked)).map(category => (
                  <div className="row" key={`cc-${category.id}`}>
                    <div className="col-lg-12 mb-3">
                      <QuickCardCategory
                        type={quickcard.id}
                        category={category[propName]}
                      />
                    </div>
                    {category.symptoms.filter(s => selectedSymptoms.includes(s.id)).map(symptom => (
                      <div className="col-lg-12 mb-3" key={'ss4-' + symptom.id}>
                        <div
                          className={`row border-radius-40 ${hasConflict(symptom, found.type)} opacity--6 px-2 align-items-center no-gutters justify-content-between`}>
                          <h5 className="mb-0 text-white">{symptom[propName]}</h5>
                          <FromComponent
                            type={found.type}
                            symptomId={symptom.id}
                            neurologistSymptomsSelection={found.neurologist_symptom_selection}
                            therapistSymptomsSelection={found.therapist_symptom_selection}
                            assessmentSymptomsSelection={found.assessment_symptom_selection}
                          />
                        </div>
                        <TherapyModules
                          symptom={symptom}
                          propName={propName}
                        />
                        <Auxiliaries
                          symptom={symptom}
                          propName={propName}
                        />
                      </div>
                    ))}
                  </div>
                ))}

              </QuickCard>}
            </div>
          </div>}
          {(includeComments && <CommentsComponent/>)}

        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({quickcard}) => ({
  lang: quickcard.lang,
  quickcards: quickcard.dataTree
})

export default connect(mapStateToProps, null)(ShowQuickcardPage)

