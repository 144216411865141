import { USER_ROLE } from '../actions/types';

const initialState = {
  type: ""
}

/**
 * Reducer for modal
 *
 * @param {state} State of this value
 * @param {action} Executing the main name of this reducer
 *
 * @return {Parameters} The different states of these reducers
 */

const userRole = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case USER_ROLE:
      return {
        ...state,
        ...payload
      }
    default:
      return {
        ...state
      }
  }
}

export default userRole;