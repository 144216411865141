import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import FooterLayout from '../core/app-layout/components/FooterLayout';
import PrimaryButton from '../shared/design/buttons/primary-button';
import {Link} from 'react-router-dom';
import AssessmentCard from './components/layout/AssessmentCard';
import QuickCard from '../LayoutComponents/quickcard';
import styles from "../LayoutComponents/quickcard/style.module.scss";
import NavigationTab from "../LayoutComponents/quickcard/components/NavigationTab";
import moment from "moment";
import CommentsComponent from "../LayoutComponents/quickcard/components/comments";
import AttachmentsComponent from "../LayoutComponents/quickcard/components/attachments";
import MedicalPlan from "../LayoutComponents/quickcard/components/medicalPlan";

const SpeechSymptomCategory = ({
                                  patientId,
                                  children,
                                  name,
                                  title,
                                  quickcard,
                                  haveDisabledTabs,
                                  createDate,
                                  updateCountNumber
                                }) => {






  const [speechQuickCardSubtype, setSpeechQuickCardSubtype] = useState(null)
  const speechQuickCardSubtypes = useSelector(state => state.quickcard.speechQuickCardSubtypes)
  const [speechSymptomCategory, setSpeechSymptomCategory] = useState(null)
  const speechSymptomCategories = useSelector(state => state.quickcard.speechSymptomCategories)

  return (
    <>
      {
        speechSymptomCategories.filter(f => f.id == "speech_dysphagia").map(cat => (
          <div onClick={() => setSpeechSymptomCategory(cat.name)}
               key={cat.id}
               className="col-12 col-md-6 col-lg-4 mb-4">
            <label htmlFor={cat.id}>{cat.title}
              <input type="radio"
                     checked={speechSymptomCategory === cat.name}
                     onChange={() => setSpeechSymptomCategory(cat.name)}/>
            </label>
          </div>
        ))
      }


      <FooterLayout>
        <Link
          to={`/patients/${patientId}/quickcards/create/${speechSymptomCategory}`}
          className="text-decoration-none"
          disabled={!speechSymptomCategory}
        >
          <PrimaryButton
            disabled={!speechSymptomCategory}>
            Weiter
          </PrimaryButton>
        </Link>
      </FooterLayout>









{/*
      <div className={`quickcard-shadow-box card-min-height ja-border-${getColor()} border-radius-20 bg-white`}>
        <div className={`${styles.header} ja-bg-${getColor()} overflow-auto overflow-customize`}>
          <div className={`${styles.header1} cursor-pointer text-center ${fontColor} 
            ${navigationTitle === title && styles.active} } ${styles.fontSize}`}>
            Logopädie
          </div>
          <div className={styles.header2}></div>
        </div>
        <div className="card-body">
          <h4
            className={`ja-color-${getColor()} mb-3 font-weight-700 font-md`}>Logopädie Quickcard-Typ auswählen</h4>
          {
            speechQuickCardSubtypes.map(subCard => (
              <div onClick={() => setSpeechQuickCardSubtype(subCard.name)}
                   key={subCard.id}
                   className="col-12 col-md-6 col-lg-4 mb-4">
                <label htmlFor={subCard.id}>{subCard.title}
                  <input type="radio"
                         checked={speechQuickCardSubtype === subCard.name}
                         onChange={() => setSpeechQuickCardSubtype(subCard.name)}/>
                </label>
              </div>
            ))
          }
        </div>
      </div>
*/}



      {/*this fn works, keep it just in case*/}
      {/*      {
        speechQuickCardSubtypes.map(subCard => (
          <div onClick={() => setSpeechQuickCardSubtype(subCard.name)}
               key={subCard.id}>
            <label htmlFor={subCard.id}>{subCard.title}
              <input type="radio"
                     checked={speechQuickCardSubtype === subCard.name}
                     onChange={() => setSpeechQuickCardSubtype(subCard.name)}/>
            </label>
          </div>
        ))
      }*/}

{/*      <FooterLayout>
        <Link
          to={`/patients/${patientId}/quickcards/create/${speechQuickCardSubtype}`}
          className="text-decoration-none"
          disabled={!speechQuickCardSubtype}
        >
          <PrimaryButton
            disabled={!speechQuickCardSubtype}>
            Weiter
          </PrimaryButton>
        </Link>
      </FooterLayout>*/}
    </>
  );
};

export default SpeechSymptomCategory;
