import React from 'react';
import {mergeClass} from '../../../functions/function';
import {sharedBtn} from './secondary-button.module.scss'
const SecondaryButton = ({
   children,
   className,
   variant = 'secondary',
   type = 'button',
   ...rest
}) => {
  return (
    <button
      className={mergeClass(`btn btn-outline-${variant} ${sharedBtn}`, className)}
      type={type}
      {...rest}>
      {children}
    </button>
  );
};

export default SecondaryButton;
