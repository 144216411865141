import http from '../http'
import {QUICKCARDS} from '../routes/api';

/**
 * Services for quickcard
 *
 * All possible methods to the different API Endpoints for quickcard
 */

const quickcardService = {
  /**
   * Get all quickcards
   *
   * @returns {Promise<*>}
   */
  list() {
    return http.get(`${QUICKCARDS}`)
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Abort a quickcard
   *
   * @param {id} ID of the quickcard
   *
   * @returns {Promise<*>}
   */
  abortQuickCard(id) {
    return http.post(`${QUICKCARDS}/${id}/abort`, {quickcardId: id})
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Close a quickcard
   *
   * @param {id} ID of the quickcard
   *
   * @returns {Promise<*>}
   */
  closeQuickCard(id) {
    return http.post(`${QUICKCARDS}/${id}/close`, {quickcardId: id})
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Revoke a quickcard
   *
   * @param {id} ID of the quickcard
   *
   * @returns {Promise<*>}
   */
  revokeQuickCard(id) {
    return http.post(`${QUICKCARDS}/${id}/therapist-revoke-assignment`)
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Manager Revoke a quickcard
   *
   * @param {id} ID of the quickcard
   *
   * @returns {Promise<*>}
   */
  managerRevokeQuickCard(id) {
    return http.post(`${QUICKCARDS}/${id}/revoke-assignment`)
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Get the data tree of a quickcard
   *
   * @returns {Promise<*>}
   */
  getTree() {
    return http.get(`${QUICKCARDS}/data-tree`)
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Assign a quickcard to the therapist
   *
   * @param {quickcardId} ID of the quickcard
   * @param {data} Object with multiple parameters
   *
   * @returns {Promise<*>}
   */
  assignTherapist(quickcardId, data) {
    return http.post(`${QUICKCARDS}/${quickcardId}/assign-therapist`, data)
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Claim a quickcard
   *
   * @param {quickcardId} ID of the quickcard
   * @param {data} Object with one parameter
   *
   * @returns {Promise<*>}
   */
  claimQuickcard(quickcardId, data) {
    return http.post(`${QUICKCARDS}/${quickcardId}/therapist/claim`, data)
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Assign a quickcard to the therapist by Manager
   *
   * @param {quickcardId} ID of the quickcard
   * @param {data} Object with multiple parameters
   *
   * @returns {Promise<*>}
   */
   managerAssignTherapist(quickcardId, data) {
    return http.post(`${QUICKCARDS}/${quickcardId}/manager/assign-therapist`, data)
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Get the data of a quickcard
   *
   * @param {quickcardId} ID of the quickcard
   *
   * @returns {Promise<*>}
   */
  getQuickCardById(quickcardId) {
    return http.get(`${QUICKCARDS}/${quickcardId}`)
      .then(res => res.data)
      .catch(err => {
        if (err.response.status === 404) {
          window.location.replace("/404")
        } else if (err.response.status === 403) {
          window.location.replace("/403")
        }
      })
  },
  /**
   * Update a quickcard
   *
   * @param {quickcardId} ID of the quickcard
   * @param {data} Object with multiple parameters
   *
   * @returns {Promise<*>}
   */
  updateQuickCardNeurologist(quickcardId, data) {
    return http.post(`${QUICKCARDS}/${quickcardId}/neurologist-assessment`, data)
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Save the data from therapist assessment of a quickcard
   *
   * @param {quickcardId} ID of the quickcard
   * @param {data} Object with multiple parameters
   *
   * @returns {Promise<*>}
   */
  saveTherapistAssessment(quickcardId, data) {
    return http.post(`${QUICKCARDS}/${quickcardId}/therapist-assessment`, data)
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Save the data from assessment center of a quickcard
   *
   * @param {quickcardId} ID of the quickcard
   * @param {data} Object with multiple parameters
   *
   * @returns {Promise<*>}
   */
  saveAssessmentCenterAssessment(quickcardId, data) {
    return http.post(`${QUICKCARDS}/${quickcardId}/assessment-center-assessment`, data)
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Save the data from complete therapist assessment of a quickcard
   *
   * @param {quickcardId} ID of the quickcard
   *
   * @returns {Promise<*>}
   */
  completeTherapistAssessment(quickcardId) {
    return http.post(`${QUICKCARDS}/${quickcardId}/complete-therapist-assessment`, {quickcardId})
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Save the data from complete assessment center of a quickcard
   *
   * @param {quickcardId} ID of the quickcard
   *
   * @returns {Promise<*>}
   */
  completeAssessmentCenterAssessment(quickcardId) {
    return http.post(`${QUICKCARDS}/${quickcardId}/complete-assessment-center-assessment`, {quickcardId})
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Send a request to the assessment center of a quickcard
   *
   * @param {quickcardId} ID of the quickcard
   *
   * @returns {Promise<*>}
   */
  requestAssessmentCenter(quickcardId) {
    return http.post(`${QUICKCARDS}/${quickcardId}/request-assessment-center`)
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Get all comments of a quickcard
   *
   * @param {quickcardId} ID of the quickcard
   *
   * @returns {Promise<*>}
   */
  getComments(quickcardId) {
    return http.get(`${QUICKCARDS}/${quickcardId}/comments`)
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Create a new comment of a quickcard
   *
   * @param {quickcardId} ID of the quickcard
   * @param {payload} Object with multiple parameters
   *
   * @returns {Promise<*>}
   */
  createComment(quickcardId, payload) {
    const formData = new FormData()
    formData.append('new_comment', JSON.stringify(payload.body))
    payload.files.map((file) => formData.append('upload_files[]', file))

    let header = false

    if (payload.files.length > 0) {
      header = true
    }

    return http.post(`${QUICKCARDS}/${quickcardId}/comments`, formData, header)
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Save a comment of a quickcard
   *
   * @param {commentId} ID of the comment
   * @param {payload} Object with multiple parameters
   *
   * @returns {Promise<*>}
   */
  saveComment(commentId, payload) {
    const formData = new FormData()
    formData.append('updated_comment', JSON.stringify(payload.body))
    payload.files.forEach(file => {
      formData.append('upload_files[]', file)
    })
    return http.post(`/comments/${commentId}`, formData, true)
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Delete a comment of a quickcard
   *
   * @param {commentId} ID of the comment
   *
   * @returns {Promise<*>}
   */
  deleteComment(commentId) {
    return http.delete(`/comments/${commentId}`)
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Get all attachments of a quickcard
   *
   * @param {quickcardId} ID of the comment
   *
   * @returns {Promise<*>}
   */
  getAttachments(quickcardId) {
    return http.get(`${QUICKCARDS}/${quickcardId}/files`)
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Begin of an therapy of a quickcard
   *
   * @param {quickcardId} ID of the comment
   *
   * @returns {Promise<*>}
   */
  beginTherapy(quickcardId) {
    return http.post(`${QUICKCARDS}/${quickcardId}/begin-therapy`)
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Download a file in a quickcard
   *
   * @param {obj} Data of this download file
   *
   * @returns {Promise<*>}
   */
  downloadFile(obj) {
    return http.get(`/comments/${obj.comment_id}/files/${obj.filename}`, {
      responseType: 'arraybuffer'
    })
      .then(res => res.data)
      .catch(err => err)
  },
  /**
   * Delete a file in a quickcard
   *
   * @param {obj} Data of this download file
   *
   * @returns {Promise<*>}
   */
  deleteFile(obj) {
    return http.delete(`/comments/${obj.comment_id}/files/${obj.filename}`)
      .then(res => res.data)
      .catch(err => err)
  },
  saveAssessmentCenterUser(quickcardId, data) {
    return http.post(`${QUICKCARDS}/${quickcardId}/assign-assessment-center`, data)
      .then(res => res.data)
      .catch(err => err)
  },
  async getOrganisationsAvailable(quickcardId) {
    return await http.get(`${QUICKCARDS}/${quickcardId}/organisations-available`)
      .then(res => res.data)
      .catch(err => err)
  },
  async quickCardAssignManager(quickcardId, data) {
    return await http.post(`${QUICKCARDS}/${quickcardId}/assign-manager`, data)
      .then(res => res.data)
      .catch(err => err)
  }
}

export default quickcardService
