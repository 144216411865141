import { FEEDBACK_CREATE } from '../actions/types';

const initialState = {
  open: false,
  success: false,
  title: "",
  message: ""
}

/**
 * Reducer for modal
 *
 * @param {state} State of this value
 * @param {action} Executing the main name of this reducer
 *
 * @return {Parameters} The different states of these reducers
 */

const feedback = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case FEEDBACK_CREATE:
      return {
        ...state,
        ...payload
      }
    default:
      return {
        ...state
      }
  }
}

export default feedback;
