import http from '../http'
import {ORGANISATION, ORGANISATIONS, QUICKCARDS, USERS} from '../routes/api';
import NotFound from "../pages/404";
import Forbidden from "../pages/403";

/**
 * Services for user
 *
 * All possible methods to the different API Endpoints for user
 */

const userService = {
  /**
   * Get all users
   *
   * @returns {Promise<*>}
   */
  async list() {
    try {
      const response = await http.get(USERS);
      return response.data
    } catch (err) {
      throw err;
    }
  },
  /**
   * Get the organisation with all users in this list
   *
   * @param {organisationId} ID of the organisation
   *
   * @returns {Promise<*>}
   */
  async listByOrganisationId(organisationId) {
    try {
      const response = await http.get(`${ORGANISATIONS}/${organisationId}${USERS}`);
      return response.data
    } catch (err) {
      throw err;
    }
  },
  /**
   * Get a specific user
   *
   * @param {userId} ID of the user
   *
   * @returns {Promise<*>}
   */
  async getUserById(userId) {
    try {
      const response = await http.get(`${USERS}/${userId}`)
      return response.data
    } catch (err) {
      throw err
    }
  },

  /**
   * Create new an user
   *
   * @param {data} Object with multiple parameter
   *
   * @returns {Promise<*>}
   */
  async store(data = {}) {
    try {
      const response = await http.post(`${ORGANISATIONS}/${data.organisation_id}${USERS}`, data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  /**
   * Update an user (Admin)
   *
   * @param {data} Object with multiple parameter
   * @param {id} ID of an user
   *
   * @returns {Promise<*>}
   */
  async update(data, id) {
    try {
      const response = await http.patch(`${USERS}/${id}`, data)
      return response.data
    } catch (err) {
      throw err;
    }
  },
  /**
   * Edit profile - Profile page
   *
   * @param {data} Object with multiple parameter
   *
   * @returns {Promise<*>}
   */
  async editProfile(data) {
    try {
      const response = await http.patch(`${ORGANISATION}/user`, data)
      return response.data
    } catch (err) {
      throw err;
    }
  },
  /**
   * Get the registration code from an user
   *
   * @param {organisationId} ID of an organisation
   * @param {userId} ID of an user
   *
   * @returns {Promise<*>}
   */
  async getRegistrationCode(userId, organisationId) {
    try {
      const response = await http.get(`${ORGANISATIONS}/${organisationId}${USERS}/${userId}/registration-code`)
      return response.data
    } catch (err) {
      throw err;
    }
  },
  /**
   * Delete an user
   *
   * @param {id} ID of an user
   *
   * @returns {Promise<*>}
   */
  async delete(id) {
    try {
      return await http.delete(`${USERS}/${id}`)
    } catch (err) {
      throw err;
    }
  },
  /**
   * Verify the access code from an user
   *
   * @param {accessCode} A generate code
   *
   * @returns {Promise<*>}
   */
  async verifyAccessCode(accessCode) {
    try {
      const response = await http.post(`/organisation/user/use-share-code`, {
        access_code: accessCode
      })
      return response.data
    } catch (err) {
      throw err;
    }
  },
  /**
   * Upload avatar image
   *
   * @param {data} Object with multiple parameter
   *
   * @returns {Promise<*>}
   */
  async uploadOrganisationAvatarImage(data) {
    let formData = new FormData()
    formData.append("organisation_logo", data)
    return await http.post(`${ORGANISATION}/logo`, formData, true
    )
      .then(res => res.data)
      .catch(err => err)
  },

  /**
   * Upload user profile picture
   *
   * @param {data} Object with multiple parameter
   *
   * @returns {Promise<*>}
   */
  async uploadUserAvatarImage(data) {
    let formData = new FormData()
    formData.append("user_profile_picture", data)
    return await http.post(`${ORGANISATION}/user/profile-picture`, formData, true
    )
      .then(res => res.data)
      .catch(err => err)
  },

  async agreeToTermsAndConditions() {
    try {
      const response = await http.post('/users/agree_tac')
      return response
    } catch (error) {
      console.error('Error:', error);}
  }
}

export default userService
