import {LOGOUT, TOGGLE_LOGOUT_MODAL} from '../actions/types';

const initialState = {
  open: false
}

/**
 * Reducer for modal
 *
 * @param {state} State of this value
 * @param {action} Executing the main name of this reducer
 *
 * @return {Parameters} The different states of these reducers
 */

const modalApp = (state = initialState, action) => {
  const {type} = action

  switch (type) {
    case TOGGLE_LOGOUT_MODAL:
      return {
        ...state,
        open: !state.open
      }
    case LOGOUT:
      return {
        ...state,
        open: false
      }
    default:
      return {
        ...state
      }
  }
}

export default modalApp;
