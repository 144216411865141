import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import quickcardService from '../../../../../services/quickcardService';
import SecondaryButton from '../../../../shared/design/buttons/secondary-button';
import { Button, Modal } from 'react-bootstrap';
import { handleDownload } from '../../../../../helpers/functions';
import helpers from '../../../../../helpers';
import * as ReactBootstrap from 'react-bootstrap';
import UploadFileCard from './uploadFile';
import UploadCard from './uploads';
import { useDispatch } from "react-redux";
import { setFeedback } from "../../../../../store/actions/feedback";

const AttachmentsComponent = ({ updateCountNumber }) => {
  const user = helpers.getTokenUser()
  let { id } = useParams()
  const [attachments, setAttachments] = useState([])
  const [attachmentsChecked, setAttachmentsChecked] = useState([])
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [attachment, setAttachment] = useState({})
  const [showQuickcardId, setShowQuickcardId] = useState("")
  const [comments, setComments] = useState([])
  const dispatch = useDispatch()


  useEffect(() => {
    let qID = "";
    if (window.location.href.indexOf("/quickcards/create") > -1) {
      let urlData = window.location.href;
      let latestPath = urlData.lastIndexOf('/');
      let result = urlData.substring(latestPath + 1);
      qID = result;
      setShowQuickcardId(qID)

      quickcardService.getAttachments(qID)
        .then(data => {
          setAttachments(data)
        })
        .catch(err => {
          console.log('FAILED FETCHING of quickcardService.getAttachments(qID). Please check the code and try again', err)
        });
    } else {
      quickcardService.getComments(id)
        .then(data => {
          setComments(data)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (setLoading(false)))
      quickcardService.getAttachments(id)
        .then(data => {
          setAttachments(data)
        })
        .catch(err => {
          console.log('FAILED FETCHING of quickcardService.getAttachments(id). Please check the code and try again', err)
        });
    }
  }, [id, showQuickcardId]);

  useEffect(() => {
    //check attachments if there belongs to the loged in user
    attachments.map((attachment) => {
      comments.map(comment => {
        if (comment.comment_id === attachment.comment_id && comment.created_by_id === user.id) {
          attachment.canEdit = true
          setAttachmentsChecked(attachments)
        } else {
          setAttachmentsChecked(attachments)
        }
      })
    })
  })

  const inputChanger = (e) => {
    uploadDirect(e.target.files[0])
  }

  const uploadDirect = (file) => {
    const files = Array(file)
    const body = {
      text: "Siehe Dateianhang."
    }
    quickcardService.createComment(id, { body, files })
      .then(data => {
        data.files[0].canEdit = true
        attachmentsChecked.push(data.files[0])
        setAttachmentsChecked(attachmentsChecked)
        dispatch(setFeedback({ open: true, success: true, message: "Der Anhang wurde erfolgreich zur Quickcard hinzugefügt." }))
      })
      .catch(err => {
        console.log(err)
        dispatch(setFeedback({ open: true, success: false, message: "Es gab ein Problem beim Hinzufügen des Anhangs" }))
      })
      .finally(() => {
        updateCountNumber()
      })
  }

  const handleDelete = () => {
    quickcardService.deleteFile(attachment)
      .then(() => {
        //** delete the whole comment */
        // quickcardService.deleteComment(attachment.comment_id)
        //   .catch(err => {
        //     console.log('FAILED DELETING quickcardService.deleteComment(comment_id). Please check the code and try again', err)
        //   })
        //   .finally(() => {
        //     setLoading(false)
        //   })
        const idx = attachmentsChecked.findIndex(a => a.id === attachment.id)
        if (idx > -1) {
          attachmentsChecked.splice(idx, 1)
          setAttachmentsChecked([...attachmentsChecked])
          setShow(false)
        }
        dispatch(setFeedback({open: true, success: true, message: "Der Anhang wurde erfolgreich gelöscht."}))
      })
      .catch(err => {
        console.log('FAILED DELETING quickcardService.deleteFile(attachment). Please check the code and try again', err)
        dispatch(setFeedback({open: true, success: false, message: "Es gab ein Problem beim Löschen dieses Anhangs"}))
      })
      .finally(() => {
        setShow(false)
        updateCountNumber()
      })
  }

  const onDelete = (attachment) => {
    setAttachment(attachment)
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
    setAttachment({})
  }

  const formatDate = (date) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
    return date && new Date(date).toLocaleString("de-DE", options).slice(0, 10);
  }

  return (
    <div className="row">
      {
        user.role !== "patient"
          ?
          <ReactBootstrap.Col xs={12} sm={12} md={6} lg={4} className={"medical-plan-card"}>
            <UploadFileCard
              callback={inputChanger}
            >
              <div>
                <i className="fas fa-plus-circle fa-5x cursor-pointer medical-plan-icon"></i>
              </div>
            </UploadFileCard>
          </ReactBootstrap.Col>
          :
          ""
      }
      {attachmentsChecked && attachmentsChecked.sort((a, b) => {
        if (a.id > b.id) return -1
      }).map((attachment, index) => (
        <ReactBootstrap.Col xs={12} sm={12} md={6} lg={4} className={"medical-plan-card"} key={index}>
          <UploadCard
            name={formatDate(attachment.created_at)}
            onDelete={() => onDelete(attachment)}
            userId={user ? user.id : null}
            canEdit={attachment.canEdit}
          >
            <div className="text-center py-2 attachment--details"
            //  onClick={() => getDownload(patientId, file.id, file.original_filename)}
            >
              <i className="fas fa-download fa-5x cursor-pointer medical-plan-icon" onClick={() => handleDownload(attachment)}></i>
              <div className="medical-plan-text">
                <div>
                </div>
                <div>
                  {attachment.filename}
                </div>
              </div>
            </div>
          </UploadCard>
        </ReactBootstrap.Col>
      ))}
      <Modal
        show={show}
        centered
        animation={false}
        contentClassName="border-radius-20 ja-text"
        onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Maßnahmen ergreifen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sind Sie sicher, dass Sie die Datei '{attachment.filename}' löschen wollen?
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton
            onClick={handleClose}
            className="mr-3">
            Abbrechen
          </SecondaryButton>
          <Button
            variant="danger"
            loading={loading}
            className="border-radius-20 px-4"
            onClick={handleDelete}>
            Löschen
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AttachmentsComponent;
