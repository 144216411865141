import { combineReducers } from 'redux'
import auth from './auth'
import searchBar from './searchBar'
import quickcard from './quickcard';
import modalApp from './modal';
import neurologist from './neurologist';
import user from './user';
import organisation from './organisation';
import feedback from './feedback';
import userRole from "./userRole";
import layout from './layout';
import alertDataRecovery from "./alertDataRecovery";

export default combineReducers({
  auth,
  searchBar,
  quickcard,
  modalApp,
  neurologist,
  user,
  organisation,
  feedback,
  userRole,
  layout,
  alertDataRecovery
})
