import {Modal} from "react-bootstrap"
import PrimaryButton from "./design/buttons/primary-button"
import React from "react"
import {connect} from "react-redux"
import {closeAlertDataRecovery} from "../../store/actions/alertDataRecovery"
import {Link} from "react-router-dom"

const AlertDataRecoverModal = ({open, closeAlertDataRecovery}) => {

  const handleCloseAlertModal = () => {
    closeAlertDataRecovery()
  }

  return (
    <>
      {
        window.location.href.indexOf("dashboard") &&
        <Modal
          size="lg"
          show={open}
          onHide={handleCloseAlertModal}
          centered
          contentClassName="border-radius-20 ja-text"
        >
          <Modal.Header
            className="border-radius-20-top ja-bg-status text-white d-flex align-items-center justify-content-center">
            <Modal.Title className="text-center">Neuigkeiten</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Liebe Kolleginnen und Kollegen,</p>
            <p>der Projektnewsletter für das Jahr 2024 ist online. Sie finden den Newsletter zum Lesen und Herunterladen{' '}
              <Link to="/static/media/Newsletter%20ParkinsonAKTIV%202024.8b924f3a.pdf" target="_blank" rel="noopener noreferrer">
                im Downloadbereich
              </Link>.</p>
            <p>
              Herzliche Grüße,
            </p>
            <p>
              Ihr ParkinsonAKTIV-Team
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-center w-100">
              <PrimaryButton
                className="px-5 py-2"
                onClick={handleCloseAlertModal}>
                Schließen
              </PrimaryButton>
            </div>
          </Modal.Footer>
        </Modal>
      }
    </>
  )
}

const mapStateToProps = state => ({
  open: state.alertDataRecovery.open
})

const mapDispatchToProps = {
  closeAlertDataRecovery
}

export default connect(mapStateToProps, mapDispatchToProps)(
  AlertDataRecoverModal)
