import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import SideBar from '../../components/LayoutComponents/sidebar';
import TopBar from '../../components/LayoutComponents/topbar';
import styles from './style.module.scss'
import quickcardService from '../../services/quickcardService';
import {setDataTree} from '../../store/actions/quickcard';
import {connect} from 'react-redux';
import {ToastContainer, toast, Zoom} from "react-toastify"
import {setFeedback} from '../../store/actions/feedback';
import 'react-toastify/dist/ReactToastify.css';
import AlertDataRecoverModal
  from "../../components/shared/AlertDataRecoverModal";

const MainLayout = ({children, feedback}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    quickcardService.getTree()
    .then(data => {
      dispatch(setDataTree(data.quickcards))
    })
  }, [dispatch]);

  /**
   * Automatic toggle the sidebar to off if breakpoint lower equal 767px
   */

  const turnOffSidebar = () => {
    if (document.body.clientWidth <= 767) {
      document.body.classList.remove('sb-sidenav-toggled')
    }
  }

  const ToastContent = () => {
    return (
        <div>
          <p className='m-0 pl-0 font-weight-bold'>{feedback.title}</p>
          <span className={`'m-0 pl-2' ${feedback.success ? 'text-success'
              : 'text-danger'}`}>{feedback.message}</span>
        </div>
    )
  }

  const notify = () => {
    toast[feedback.success ? 'success' : 'error'](<ToastContent/>, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Zoom,
      onClose: () => dispatch(
          setFeedback({open: false, message: "", success: false}))
    });
  }

  const handleClick = () => {
    dispatch(setFeedback({open: false, message: "", title: "", success: false}))
  }

  return (
      <>
        <div className="d-flex" id="wrapper" onClick={handleClick}>
          <SideBar/>
          <div id="page-content-wrapper">
            {/*Top navigation*/}
            <TopBar/>
            {/*Page content*/}
            <div
                className={`${styles.layout} ${styles.mainBlock} container-xl container-fluid p-4`}
                onClick={turnOffSidebar}>
              {children}
              {feedback.open ? notify() : ''}
              <ToastContainer limit={1}/>
            </div>
          </div>
        </div>

        {/*Feature disabled due the ticket request #PKSNAKT-1388*/}
        {/*<AlertDataRecoverModal/>*/}

      </>

  );
};

const mapStateToProps = (state) => {
  return {
    feedback: state.feedback
  }
}
export default connect(mapStateToProps, {})(MainLayout);
