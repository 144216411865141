import React from 'react';
import classNames from 'classnames';
import styles from './style.module.scss';

const UploadFileCard = (
  {
    name,
    children,
    headerClassname,
    callback
  }
) => {
  return (
    <div className={`card border-radius-20 card-min-height h-100 bg-white ${styles.border}`}>
      <div className={classNames([styles.header, headerClassname])}>
        <div className={`${styles.title} d-flex align-items-center`}>
          <span className="flex-fill-two">{"Anhang hinzufügen"}</span>
        </div>
      </div>
      <div className={classNames([styles.body, styles.minHeight])}>
        <form>
          <label htmlFor="upload-file">
            {children}
            <div>
              <input type="file" id="upload-file" onChange={callback} hidden/>
            </div>
          </label>
        </form>
      </div>
    </div>
  );
};

export default UploadFileCard;
