import React from 'react';
import NavTab from '../../shared/NavTab';
import helpers from "../../../helpers";
import Forbidden from "../../../pages/403"

const AdminLayout = ({children}) => {
  const user = helpers.getTokenUser()
  return (
    <>
    {
      user.role === 'admin' ?
        <>
          <div className="p-2">
            <div className="row mt-3">
              <div className="col-lg-12">
                <NavTab/>
              </div>
            </div>
            {children}
          </div>
        </>
        : <Forbidden/>
    }
    </>
  );
};

export default AdminLayout;
