import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import * as ReactBootstrap from 'react-bootstrap';
import UploadFileCard from './uploadFile';
import UploadCard from './uploads';
import quickcardService from '../../../../../services/quickcardService';
import helpers from '../../../../../helpers';
import PrimaryButton from '../../../../shared/design/buttons/primary-button';
import SecondaryButton from '../../../../shared/design/buttons/secondary-button';
import medicalService from "../../../../../services/medicalService";
import {handleDownload} from "../../../../../helpers/functions";
import {Button, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import medicationPlan from "../../../../../assets/documents/PNM+Quickcard_Medikationsplan.pdf"
import { useDispatch } from 'react-redux';
import { setFeedback } from '../../../../../store/actions/feedback';

const MedicalPlan = ({color}) => {
  let {id} = useParams()
  const user = helpers.getTokenUser()

  const [allFiles, setAllFiles] = useState([])
  const [patientData, setPatientData] = useState([])
  const [patientId, setPatientId] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [medicalPlanNumber, setMedicalPlanNumber] = useState(0)
  const [medicalPlanDescription, setMedicalPlanDescription] = useState("")
  const dispatch = useDispatch()

  const userData = useSelector(state => state.auth.user)

  useEffect(() => {
    if(window.location.href.indexOf("/quickcards/create") > -1){
      const qId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
      quickcardService.getQuickCardById(qId)
        .then(data => {
          setPatientData(data.patient)
          setPatientId(data.patient.id)
        })
    } else {
      quickcardService.getQuickCardById(id)
        .then(data => {
          setPatientData(data.patient)
          setPatientId(data.patient.id)
        })
    }
  }, [])

  useEffect(() => {
    if(patientId != null){
      if(window.location.href.indexOf("/quickcards/create") > -1){
        medicalService.getFiles(id)
        .then(data => {
          setAllFiles(data.medicationPlans)
        })
      } else {
        medicalService.getFiles(patientId)
        .then(data => {
          setAllFiles(data.medicationPlans)
        })
      }
    }
  }, [patientId])

  const inputChanger = (e) => {
    //let pdfFile = document.querySelector('#upload-file');
    uploadDirect(e.target.files[0])
  }

  const getDownload = (patientId, medicationPlanId, filename) => {
    medicalService.getDownloadFile(patientId, medicationPlanId)
      .then(data => {
        let blob = new Blob([data])

        const anchor = document.createElement('a')
        anchor.download = filename
        anchor.href = URL.createObjectURL(blob)
        anchor.click()
        URL.revokeObjectURL(URL.createObjectURL(blob));
      })
  }

  const deleteFile = (patientId, medicationPlanId) => {
    medicalService.deleteMedicalPlan(patientId, medicationPlanId)
      .then(data => {
        medicalService.getFiles(patientId)
          .then(data => {
            setAllFiles(data.medicationPlans)
          })
        setShowModal(false)
        dispatch(setFeedback({ open: true, success: true, message: "Datei erfolgreich gelöscht" }))
      })
      .catch((err)=>{
        console.error(err)
        dispatch(setFeedback({ open: true, success: false, message:"Es gab ein Problem beim Löschen der Datei" }))
      })
  }

  const uploadDirect = (file) => {
    medicalService.storeFile(patientId, file)
      .then(() => {
        medicalService.getFiles(patientId)
          .then(data => {
            setAllFiles(data.medicationPlans)
          })
          dispatch(setFeedback({open: true, success: true, message:"Der Medikationsplan wurde erfolgreich zum Patientenprofil hinzugefügt."}))
      })
      .catch(e => {
        console.log(e)
        dispatch(setFeedback({open: true, success: false, message: "Es gab ein Problem beim Hinzufügen der Datei"}))
      })
  }

  const formatDate = (date) => {
    const options = {year: 'numeric', month: '2-digit', day: '2-digit'}

    return date && new Date(date).toLocaleString("de-DE", options).slice(0, 10);
  }

  const openModalWithId = (medicalPlanId, medicalPlanGetDescription) => {
    setShowModal(true);
    setMedicalPlanNumber(medicalPlanId);
    setMedicalPlanDescription(medicalPlanGetDescription);
  }

  return (
    <ReactBootstrap.Row id="medical-plan">
      <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12}>
        <h5 className="ja-text-gray">
          Nur PDF-Dokumente werden unterstützt.
          Informationen und Vorlage zum Ausfüllen des bundeseinheitlichen Medikationsplans: <a className="ja-text-gray text-decoration-underline" href={medicationPlan} rel="noreferrer" target="_blank" download>Quickcard Medikationsplan</a>.
        </h5>
      </ReactBootstrap.Col>
      {
        user.role === "neurologist"
          ?
          <ReactBootstrap.Col xs={12} sm={12} md={6} lg={4} className={"medical-plan-card"}>
            <UploadFileCard
              callback={inputChanger}
            >
              <div>
                <i className="fas fa-plus-circle fa-5x cursor-pointer medical-plan-icon"></i>
              </div>
            </UploadFileCard>
          </ReactBootstrap.Col>
          :
          ""
      }
      {allFiles.map(file => {
        return (
          <ReactBootstrap.Col xs={12} sm={12} md={6} lg={4} className={"medical-plan-card"}>
            <UploadCard
              name={formatDate(file.created_at)}
              onDelete={() => openModalWithId(file.id, file.original_filename)}
              userId={userData && userData.id === file.neurologist.id ? userData.id : file.neurologist.id}
            >
              <div className="text-center medical-plan-download-file" onClick={() => getDownload(patientId, file.id, file.original_filename)}>
                <i className="fas fa-file-pdf fa-5x cursor-pointer medical-plan-icon"></i>
                <div className="medical-plan-text">
                  <div>
                    {file.original_filename}
                  </div>
                  <div>
                    {file.neurologist.firstname} {file.neurologist.lastname}
                  </div>
                </div>
              </div>
              <div>
                <ReactBootstrap.Modal
                  show={showModal}
                  centered
                  animation={false}
                  contentClassName="border-radius-20 ja-text"
                  onHide={() => setShowModal(false)}
                >
                  <ReactBootstrap.Modal.Header closeButton>
                    <ReactBootstrap.Modal.Title>Maßnahmen ergreifen</ReactBootstrap.Modal.Title>
                  </ReactBootstrap.Modal.Header>
                  <ReactBootstrap.Modal.Body>
                    Sind Sie sicher, dass Sie die Datei '{medicalPlanDescription}' löschen wollen?
                  </ReactBootstrap.Modal.Body>
                  <ReactBootstrap.Modal.Footer>
                    <SecondaryButton
                      onClick={() => setShowModal(false)}
                      className="mr-3"
                    >
                      Abbrechen
                    </SecondaryButton>
                    <ReactBootstrap.Button
                      variant="danger"
                      className="border-radius-20 px-4"
                      onClick={() => deleteFile(patientId, medicalPlanNumber)}
                    >
                      Löschen
                    </ReactBootstrap.Button>
                  </ReactBootstrap.Modal.Footer>
                </ReactBootstrap.Modal>
              </div>
            </UploadCard>
          </ReactBootstrap.Col>
        )
      })}
    </ReactBootstrap.Row>
  )
}

export default MedicalPlan;
