import React, {useEffect} from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
// Redux
import {Provider} from 'react-redux'
import store from './store'
import RouterOutlet from './router'

const App = () => {


  return (
    <Provider store={store}>
      <Router>
        <RouterOutlet/>
      </Router>
    </Provider>
  )
}

export default App

