import {QUICK_CARD} from '../actions/types';

const initialState = {
  step: 1,
  patients: []
}

/**
 * Reducer for user role 'neurologist'
 *
 * @param {state} State of this value
 * @param {action} Executing the main name of this reducer
 *
 * @return {Parameters} The different states of these reducers
 */

const neurologist = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case QUICK_CARD:
      return {
        ...state
      }
    default:
      return state;
  }
}

export default neurologist;
