import React  from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

/**
 * Error page
 *
 * If a page not available, we come to this page
 */

const Index = () => {
  return (
    <>
    <div className="no-search-result-message">
      <h4>403</h4>
      <h4>Diese Seite ist für Sie nicht verfügbar.</h4>
      <h5>Sie haben nicht die Erlaubnis, diese Seite zu besuchen.</h5>
    </div>
    </>
/*    <div
      style={{
        minHeight: 'calc(100vh - 500px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Helmet title="Not Found" />
      <div
        style={{
          maxWidth: '560px',
          backgroundColor: '#fff',
          padding: '80px 30px',
          margin: '100px auto',
          borderRadius: '10px',
          flex: '1',
        }}
      >
        <div
          style={{
            maxWidth: '400px',
            margin: '0 auto',
          }}
        >
          <h1 className="font-size-36 mb-2">404</h1>
          <p className="mb-3">Diese Seite ist leider nicht verfügbar.</p>
          <h1 className="font-size-80 mb-4 font-weight-bold">404 —</h1>
        </div>
      </div>
    </div>*/
  )
}

export default Index
