import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import helpers from "../../../helpers"
import logo from "../../../assets/img/logo.svg"
import {setSidebarOpen} from '../../../store/actions/layout'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Modal} from "react-bootstrap"
import SecondaryButton from "../../shared/design/buttons/secondary-button"

const TopBar = ({user}) => {
  const userToken = helpers.getTokenUser()
  const [overlayVisible, setOverlayVisible] = useState(false)
  const dispatch = useDispatch()
  const sidebarOpen = useSelector(state => state.layout.open)
  const role = useSelector(state => state.userRole.type)
  const [impersonationAlert, setImpersonationAlert] = useState("")
  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem('auth|user')))
  },[])

  useEffect(()=>{
    if (userInfo){
      if(userInfo.impersonation){
        setImpersonationAlert("Imitationsmodus - Aktiv. Zum Beenden bitte auf ‘Abmelden’ klicken.")
      }
    }
  })

  const toggleSidebar = () => {
    document.body.classList.toggle('sb-sidenav-toggled')
    dispatch(setSidebarOpen({open: !sidebarOpen}))
  }

  const toggleHelpOverlay = () => {
    if (!overlayVisible) {
      document.querySelector(".help-overlay").classList.add("help-show")
      document.querySelector(".help-overlay").classList.remove("help-hide")
      setOverlayVisible(true)
    } else {
      document.querySelector(".help-overlay").classList.remove("help-show")
      document.querySelector(".help-overlay").classList.add("help-hide")
      setOverlayVisible(false)
    }
  }

  const closeHelpOverlay = () => {
    document.querySelector(".help-overlay").classList.remove("help-show")
    document.querySelector(".help-overlay").classList.add("help-hide")
    setOverlayVisible(false)
  }
  return (
    <>
    <nav className="navbar navbar-expand-lg navbar-light ja-navbar py-0 px-2">
      <div className="container-fluid">
        <button className="btn btn-outline-secondary" id="sidebarToggle" onClick={toggleSidebar}>
          <i className="fas fa-bars"/>
        </button>
        <div className="sidebar-heading ja-navbar d-flex justify-content-center align-items-center d-md-none">
          <img src={logo} alt="" width="150" className="my-"/>
        </div>
        <div className="d-flex right-nav">
          <div
            className={userToken.role !== "admin" ? "mr-4 mr-md-5 text-center help-overlay-link-view" : "text-center help-overlay-link-view"}
            onClick={toggleHelpOverlay}>
            <div><i className="far fa-question-circle fa-2x"/></div>
            <div>Hilfe</div>
          </div>
          {
            userToken.role !== "admin" && (
              <div className="text-center profile-header">
                <Link to="/profile">
                  <div><i className="far fa-user fa-2x"/></div>
                  <div>Profil</div>
                </Link>
              </div>
            )
          }
        </div>
      </div>
      <div className="help-overlay help-hide">
        <div className="text-center help-headline">
          Hilfe
        </div>
        <div className="text-justify help-overlay-context">
          <div>
            Vielen Dank für die Nutzung von JamesAKTIV! Diese digitale Kommunikationsplattform soll es Versorgern und
            Patienten ermöglichen, aktiv zu kooperieren und aktivierende Therapien durchzuführen.
          </div>
          <div>
            Bei Fragen und Anregungen zu JamesAKTIV würden wir uns sehr über Ihre Nachricht freuen. Schreiben Sie dazu
            der Vernetzungsstelle:
            <div>
              <a href="mailto:pnmplus@ukmuenster.de">pnmplus@ukmuenster.de</a>
            </div>
          </div>
          <div>
            JamesAKTIV wird im Rahmen des Innovationsfondsprojeks <a
            href="https://innovationsfonds.g-ba.de/projekte/neue-versorgungsformen/parkinson-aktiv-parkinson-aktivierende-therapien-im-innovativen-versorgungsnetz.351"
            target="_blank">Parkinson AKTIV</a> entwickelt und evaluiert.
          </div>

          {userToken.role !== "admin" && (
            (userToken.role === 'patient' &&
              (<div onClick={closeHelpOverlay}>
                <Link to="/faq-patient">
                  Häufige Fragen und Antworten (FAQ)
                </Link>
              </div>))
            ||
            (userToken.role === 'therapist_physio' || 'therapist_speech' || 'therapist_occupational' || 'parkinson_nurse'
              || userToken.role === 'neurologist') &&
            (<div onClick={closeHelpOverlay}>
              <Link to="/faq-professional">
                Häufige Fragen und Antworten (FAQ)
              </Link>
            </div>)
          )}
        </div>
        <div className="help-close help-overlay-link-view" onClick={closeHelpOverlay}>
          <i className="fas fa-times fa-2x"></i>
        </div>
      </div>
    </nav>
      <br/>
      <div className="row col-lg-12 d-flex justify-content-center align-items-center">
        <span className="col-lg-12 d-flex justify-content-center align-items-center">
          <h4 className="text-danger">{impersonationAlert}</h4>
        </span>
      </div>
    </>
  )
}

export default TopBar
