import React, {useState, useEffect} from 'react';
import SecondaryButton from '../../../../../shared/design/buttons/secondary-button';
import PrimaryButton from '../../../../../shared/design/buttons/primary-button';
import quickcardService from '../../../../../../services/quickcardService';
import moment from 'moment';
import styles from './style.module.scss'
import {handleDownload} from '../../../../../../helpers/functions';
import {setFeedback} from '../../../../../../store/actions/feedback';
import {useDispatch} from 'react-redux';
import {Modal} from 'react-bootstrap';

const Comment = (
  {
    color,
    quickcardId,
    comment,
    onCloseNew,
    onDelete,
    canUserEdit,
    user,
    updateCountNumber,
    commentOwnerData,
    isAdminComment,
    isCurrentAdminUser
  }
) => {

  const [commentText, setCommentText] = useState(comment.text)
  const [canEdit, setCanEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [fileNames, setFileNames] = useState([])
  const [show, setShow] = useState(false)
  const [attachment, setAttachment] = useState({})
  const dispatch = useDispatch()

  const handleChange = (e) => {
    setCommentText(e.target.value)
  }

  const handleDelete = () => {
    setLoading(true)
    quickcardService.deleteFile(attachment)
      .then(() => {
        const idx = comment.files.findIndex(a => a.id === attachment.id)
        const i = files.findIndex(a => a.id === attachment.id)
        if (idx > -1) {
          comment.files.splice(idx, 1)

          // setAttachments([...comment.files])
          setShow(false)
        }
        if (i > -1) {
          comment.files.splice(i, 1)
          comment.files.map(f => {
            f.filename = f.name
          })
          setFiles(comment.files)
          // setAttachments([...comment.files])
          setShow(false)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
        updateCountNumber()
      })
  }

  const onDeleteAttachment = (attachment) => {
    setAttachment(attachment)
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
    setAttachment({})
  }

  const handleSave = () => {

    const cQId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);

    const mainId = window.location.href.indexOf("/quickcards/create") > -1 ? cQId : quickcardId;
    const body = {
      text: commentText
    }
    const action = comment.comment_id === 0 ? 'createComment' : 'saveComment'
    const resourceId = comment.comment_id === 0 ? mainId : comment.comment_id

    setLoading(true)
    quickcardService[action](resourceId, {body, files})
      .then(data => {
        comment.comment_id = data.comment_id
        comment.files.map(f => {
          f.comment_id = data.comment_id
          f.filename = f.name || f.filename
        })
        files.map(f => {
          f.comment_id = data.comment_id
          f.filename = f.name || f.filename
          comment.files.push(f)
        })
        delete comment.isNew
        onCloseNew()
        setCanEdit(false)
        action === "createComment" ? dispatch(setFeedback({
          open: true,
          success: true,
          message: "Ihr Kommentar wurde erfolgreich angelegt."
        })) : dispatch(setFeedback({
          open: true,
          success: true,
          message: "Ihr Kommentar wurde erfolgreich aktualisiert."
        }))
      })
      .catch(err => {
        console.log(err)
        action === "createComment" ? dispatch(setFeedback({
          open: true,
          success: true,
          message: "Es gab ein Problem bei der Anlegung des Kommentars"
        })) : dispatch(setFeedback({
          open: true,
          success: true,
          message: "Es gab ein Problem bei der Aktualisierung des Kommentars"
        }))
      })
      .finally(() => {
        setLoading(false)
        updateCountNumber()
        setFiles([])
        setFileNames([])
      })
  }

  useEffect(() => {
    if (comment.isNew) {
      setCanEdit(true)
      comment.text = commentText
    }
  }, [comment, commentText]);

  const onCancel = () => {
    setCanEdit(false)
    setFileNames([])
    setFiles([])
    if (comment.isNew) onCloseNew()
  }

  const handleUpload = (e) => {
    const arr = Array.from(e.target.files)
    arr.forEach(f => fileNames.push(f.name))
    setFileNames([...fileNames])
    files.push(...e.target.files)
    setFiles(files)

  }

  const removeFile = (i) => {
    files.splice(i, 1)
    setFiles(files)
    let names = [...fileNames]
    names.splice(i, 1)
    setFileNames(names)
  }

  return (
    <div className="row mb-4">
      <div className="col-lg-12 d-flex">
        <div className="comment-author mr-auto">
          <h6 className={`ja-color-${color}`}>
            {
              comment.comment_id
                ? isAdminComment
                  ? isCurrentAdminUser
                    ? `${user.firstname} ${user.lastname} (Vernetzungsstelle), ${moment(comment.created_at).format('DD.MM.YYYY')}`
                    : `Vernetzungsstelle, ${moment(comment.created_at).format('DD.MM.YYYY')}`
                  : `${user.firstname} ${user.lastname}, ${moment(comment.created_at).format('DD.MM.YYYY')}`
                : 'Neuer Kommentar'
            }
          </h6>
        </div>
        {!comment.isNew && <div className="comment-actions">
          {canUserEdit && (
            <>
              <i className="far fa-edit mr-2 cursor-pointer mr-2" onClick={() => setCanEdit(true)}/>
              <i className="fas fa-trash-alt cursor-pointer" onClick={() => onDelete(comment.comment_id)}/>
            </>
          )}
        </div>}
      </div>
      <div className="col-lg-12">
        {
          canEdit
            ? (
              <>
                <textarea className="login-input form-control rounded comment-input " value={commentText}
                          onChange={handleChange}/>
                {comment.files.map(file => (
                  <>
                    <span className={`ja-text-gray cursor-pointer edit-comment ${styles.commentAttachment}`}>
                      <i className="fa fa-file"/>
                      <span className={`${styles.commentAttachmentText}`}>
                        {file.filename}
                      </span>
                      <span className="ml-3">
                        <i className="fas fa-download cursor-pointer" onClick={() => handleDownload(file)}/>
                        <i className="fas fa-times cursor-pointer px-2" onClick={() => onDeleteAttachment(file)}/>
                      </span>
                    </span>
                  </>
                ))}
              </>
            )
            : (
              <div className={`p-2 read-comment-container ${styles.commentDisplay}`}>
                <div
                  className={`login-input read-comment form-control border-0 ${comment.files.length > 0 && 'pt-1'} bg-white `}>
                  {commentText}
                </div>
                {comment.files.map((file, i) => (
                  <span className={`ja-text-gray cursor-pointer d-flex align-items-center px-2`} key={i}>
                    <i className="fa fa-file"/>
                    <span className={`${styles.commentAttachmentText} d-block text-truncate`}>
                      {file.filename}
                    </span>
                    <span className="ml-3">
                      <i className="fas fa-download cursor-pointer" onClick={() => handleDownload(file)}/>
                    </span>
                  </span>
                ))}
              </div>
            )
        }
      </div>
      {canEdit && (<>
        <div className="col-lg-12 d-flex justify-content-between mt-2 align-items-center">
          <div className="flex-grow-1">
            <div className="input--file" style={{width: '30px'}}>
              <i className="fas fa-paperclip fa-2x"/>
              <input type="file" onChange={handleUpload} multiple/>
            </div>
          </div>
          <SecondaryButton
            className="mr-3"
            disabled={loading}
            onClick={onCancel}>
            Abbrechen
          </SecondaryButton>
          <PrimaryButton
            loading={loading}
            empty={commentText ? false : true}
            onClick={handleSave}>
            Speichern
          </PrimaryButton>
        </div>
        <div className="row px-3 overflow-hidden text-break">
          {fileNames.map((file, key) => (
            <div className="col-lg-12 ja-text-gray" key={key}>
              <span className="ja-text-gray">{file}</span>
              <i className="fas fa-times cursor-pointer px-2" onClick={() => removeFile(key)}/>
            </div>
          ))}
        </div>
      </>)
      }
      <Modal
        show={show}
        centered
        animation={false}
        contentClassName="border-radius-20 ja-text"
        onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Maßnahmen ergreifen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sind Sie sicher, dass Sie die Datei '{attachment.filename || attachment.name}' löschen wollen?
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton
            onClick={handleClose}
            className="mr-3">
            Abbrechen
          </SecondaryButton>
          <PrimaryButton
            loading={loading}
            onClick={handleDelete}>
            Löschen
          </PrimaryButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Comment;
