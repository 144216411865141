import React from 'react';

const QuickCardCategory = (
  {
    category,
    type,
    select_min,
    select_max
  }
) => {
  return (
    <div className={`row border-radius-40 ja-bg-${type} d-flex justify-content-center opacity--8 align-items-center p-2 ja-border-${type} no-gutters`}>
      <h5 className="mb-0 text-white">{category}
        {(select_min !==undefined && select_max !== undefined) ? ` (${select_min} bis zu ${select_max} auswählbar)`: ""}
      </h5>
    </div>
  );
};

export default QuickCardCategory;
