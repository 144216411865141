import React from 'react';

const FooterLayout = ({children, className}) => {
  return (
    <div className={`row footer-new ${className}`}>
      <div className="col-md-12 p-0">
        <div className="shadow-sm-top p-3 text-right">
          {children}
        </div>
      </div>
    </div>
  );
};

export default FooterLayout;
