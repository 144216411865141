import React, {useEffect} from 'react';
import moment from 'moment';
import quickcard from '../../../../store/reducers/quickcard';
import {Link} from 'react-router-dom';

const StatusBar = (
  {
    patient,
    neurologist,
    found,
    createDate,
    updateDate,
    isAborted,
    therapist,
    hasAssessmentUser
  }
) => {
  // const options = { year: 'numeric', month: '2-digit', day: '2-digit' }

  // const createDateStatus = new Date(createDate).toLocaleString("de-DE", options).slice(0, 10);
  //const createDateStatus = createDate ? moment(quickcard.creation_moment).format('DD.MM.YYYY') : ''
  //const updateDateStatus = updateDate ? moment(quickcard.update_moment).format('DD.MM.YYYY') : ''

  const deQuickCardState = {
    'new': 'Neu',
    'assessment_neurologist': 'Diagnose Neurologe',
    'assessment_therapist': 'Diagnose Therapeut',
    'consensus': 'In Klärung',
    'assessment_external': 'Assessment-Zentrum',
    'therapy': 'In Therapie',
    'closed': 'Abgeschlossen',
    'aborted': 'Abgebrochen',
    'consensus_completed': 'Quickcard geklärt'
  }

  return (
    <>
      <div className="border-radius-40 bg-white row align-items-center p-3 p-md-4 ja-border-gray no-gutters">
        <div className="col-xl-4 col-lg p-1 d-flex justify-content-between d-lg-block">
          <span className="ja-text font-weight-500 mr-2">Name: </span>
          <span className="ja-text-gray">
            {
              patient && <Link to={`/patients/${patient.id}`} className="ja-text-gray text-decoration-none">
                {`${patient.firstname} ${patient.lastname}`}
              </Link>
            }
          </span>
        </div>
        {
          neurologist && (
            <div className="col-xl-4 col-lg-6 p-1 d-flex justify-content-between d-lg-block">
              <span className="ja-text font-weight-500 mr-2">Neurologe: </span>
              {
                neurologist && <Link to={`/users/${neurologist.id}`} className="ja-text-gray text-decoration-none">
                  {`${neurologist.firstname} ${neurologist.lastname}`}
                </Link>
              }
            </div>
          )
        }
        <div className="col-xl-4 col-lg-6 p-1 d-flex justify-content-between d-lg-block">
          <span className="ja-text font-weight-500 mr-2">Erstellungsdatum: </span>
          <span className="ja-text-gray">{createDate}</span>
        </div>
        {found.state && (
          <div className="col-xl-4 col-lg-6 p-1 d-flex align-items-center justify-content-between d-lg-block">
            <span className="ja-text font-weight-500 mr-2">Status: </span>
            {found.state && <span className="text-white p-2 border-radius-20"
                                  style={{backgroundColor: isAborted ? '#D35033' : '#2178B9'}}>
            {deQuickCardState[found.state] || ''}
          </span>}
          </div>
        )}
        {therapist && therapist.firstname && (
          <div className="col-xl-4 col-lg-6 p-1 d-flex justify-content-between d-lg-block">
            <span className="ja-text font-weight-500 mr-2">Therapeut: </span>
            {therapist ? (
              <Link to={`/users/${therapist.id}`} className={'ja-text-gray text-decoration-none'}>
                {therapist.firstname} {therapist.lastname}
              </Link>
            ) : (
              <span className="ja-text-gray"> </span>
            )}
          </div>
        )}
        {updateDate && (
          <div className="col-xl-4 col-lg-6 p-1 d-flex justify-content-between d-lg-block">
            <span className="ja-text font-weight-500 mr-2">Aktualisierungsdatum: </span>
            <span className="ja-text-gray">{updateDate}</span>
          </div>
        )}
        {hasAssessmentUser && (
          <div className="col-xl-4 col-lg-6 p-1 d-flex justify-content-between d-lg-block">
            <span className="ja-text font-weight-500 mr-2">Assessment-Zentrum: </span>
            <span className="ja-text-gray">{hasAssessmentUser.firstname} {hasAssessmentUser.lastname}</span>
          </div>
        )}
      </div>
    </>
  )
}

export default StatusBar;
