import {
  CLOSE_DATA_RECOVERY_MODAL,
  OPEN_DATA_RECOVERY_MODAL,
} from '../actions/types';

const initialState = {
  open: false
}

const alertDataRecovery = (state = initialState, action) => {
  const {type} = action

  switch (type) {
    case OPEN_DATA_RECOVERY_MODAL:
      return {
        ...state,
        open: true
      }
    case CLOSE_DATA_RECOVERY_MODAL:
      return {
        ...state,
        open: false
      }
    default:
      return {
        ...state
      }
  }
}

export default alertDataRecovery;
