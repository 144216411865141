import React from 'react';
import classNames from 'classnames';
import styles from './style.module.scss';

const UploadCard = (
  {
    name,
    children,
    headerClassname,
  }
) => {

  return (
    <div className={`card border-radius-20 card-min-height h-100 w-25 mx-auto my-4 bg-white ${styles.border}`}>
      <div className={classNames([styles.header, headerClassname])}>
        <div className={`${styles.title} d-flex align-items-center`}>
          <span className="flex-fill">{name || "N/A"}</span>
        </div>
      </div>
      <div className={classNames([styles.body, styles.minHeight])}>
        {children}
      </div>
    </div>
  );
};

export default UploadCard;
