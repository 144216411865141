import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Link, useHistory, useLocation} from 'react-router-dom'
import logo from '../../../assets/img/logo.svg'
import {
  AdminSidebarData,
  NeurologistSidebarData,
  PatientSidebarData,
  TherapistSidebarData,
} from './data'
import {toggleLogoutModal} from '../../../store/actions/modal'
import {Button, Modal} from "react-bootstrap"
import SecondaryButton from "../../shared/design/buttons/secondary-button"
import helpers from "../../../helpers"
import {doLogout} from "../../../store/actions/auth"
import {useDispatch} from 'react-redux'
import {setAuthDetails} from '../../../store/actions/auth'
import {setFeedback} from '../../../store/actions/feedback'

const SideBar = ({toggleLogoutModal, user, doLogout}) => {
  const userToken = helpers.getTokenUser()
  const [impersonate, setImpersonate] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const handleImpersonateModal = () => setShowModal(true)
  const handleCloseImpersonateModal = () => setShowModal(false)
  const dispatch = useDispatch()

  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem('auth|user')))

  }, [])

  useEffect(() => {
    if (userInfo) {
      if (userInfo.impersonation) {
        setImpersonate(true)
      }
    }
  })


  const history = useHistory()
  // const logout = async () => {
  //   localStorage.removeItem("URL");
  //   await doLogout()
  //   history.push('/admin/login')
  // }

  let sessToken = sessionStorage.getItem("ja2token")
  let sessRFToken = sessionStorage.getItem("rftk")
  let authUser = sessionStorage.getItem("auth|user")
  const myPromise = new Promise(function (resolve, reject) {
    if (sessToken && sessRFToken && authUser) {
      resolve()
    }
    /*    else {
          reject()
        }*/
  })

  const logout = async () => {
    myPromise
      .then(() => {
        localStorage.setItem("ja2token", sessToken)
        localStorage.setItem("rftk", sessRFToken)
        localStorage.setItem("auth|user", authUser)
        localStorage.removeItem(process.env.REACT_APP_IMITATION_FLAG)
        let userInfo = JSON.parse(authUser)
        dispatch(setAuthDetails({...userInfo, impersonation: false}))
      })
      .then(history.push("/dashboard"))
      .catch(err => {
        console.error(err)
        dispatch(setFeedback({
          open: true,
          success: false,
          message: "es gab ein problem. bitte versuchen sie es noch einmal"
        }))
      })
  }

  let location = useLocation()
  let sidebarLinks = []
  if (user) {
    sidebarLinks = {
      admin: AdminSidebarData,
      neurologist: NeurologistSidebarData,
      therapist: TherapistSidebarData,
      patient: PatientSidebarData,
    }[user.role.split('_')[0]]
  }

  const turnOffSidebar = () => {
    if (document.body.clientWidth <= 767) {
      document.body.classList.remove('sb-sidenav-toggled')
    }
  }


  return (
    <div className="bg-white" id="sidebar-wrapper">
      <div className="sidebar-heading ja-navbar d-none d-md-flex justify-content-center align-items-center">
        <img src={logo} alt="" width="160"/>
      </div>
      <div className="list-group list-group-flush">
        {sidebarLinks.map(link => (
          <Link
            key={link.path}
            to={link.path}
            onClick={turnOffSidebar}
            className={`${(location.pathname.startsWith(link.path) || (/admin/.test(link.path) && /admin/.test(location.pathname))) && 'active'} list-group-item list-group-item-action list-group-item-light border-bottom-0 text-center list-item-position`}>
            <div onClick={() =>
              link.path === '/video-portal' && window.location.pathname === '/video-portal' ? window.location.replace("/video-portal")
                : link.path === '/download-portal' && window.location.pathname === '/download-portal' ? window.location.replace("/download-portal") : null}
            >
              <span className="d-block icon-spacing "><i className={`${link.icon}`}/></span>
              <span className="d-block">{link.name}</span>
            </div>
            {/*<span className="d-block icon-spacing"><i className={`${link.icon}`}/></span>
              <span className="d-block">{link.name}</span>*/}
          </Link>
        ))}
        {/*Logout*/}
        <div
          className="list-group-item list-group-item-action list-group-item-light border-bottom-0 text-center logout-link cursor-pointer list-item-position"
          onClick={impersonate ? handleImpersonateModal : toggleLogoutModal}>
          <span className="d-block icon-spacing"><i className="fas fa-sign-out-alt fa-2x"/></span>
          <span className="d-block">Abmelden</span>
        </div>

        <Modal
          show={showModal}
          onHide={handleCloseImpersonateModal}
          centered
          backdrop="static"
          keyboard={false}
          animation={false}
          contentClassName="border-radius-20 ja-text"
          id="action-modal">
          <Modal.Header closeButton>
            <Modal.Title>Imitationsmodus</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Wollen Sie in den ‘Imitationsmodus’ beenden und zum Admin-Dashboard zurückkehren?
          </Modal.Body>
          <Modal.Footer>
            <SecondaryButton
              className="border-radius-20"
              onClick={handleCloseImpersonateModal}>
              Abbrechen
            </SecondaryButton>
            <Button
              variant="primary"
              className="border-radius-20"
              //onClick={toggleLogoutModal}
              onClick={logout}
            >
              Beenden
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  toggleLogoutModal,
  doLogout
}

const mapStateToProps = ({auth, searchBar}) => ({
  user: auth.user,
  showSidebar: searchBar,
})

export default connect(mapStateToProps, mapDispatchToProps)(SideBar)
