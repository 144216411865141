import React, { useEffect } from 'react';
import classNames from 'classnames';
import styles from './style.module.scss';
import helpers from "../../../../../../helpers";
import {useSelector} from "react-redux";

const UploadCard = (
  {
    name,
    children,
    onDelete,
    headerClassname,
    userId,
    canEdit
  }
) => {
  const user = helpers.getTokenUser()
  const userData = useSelector(state => state.auth.user)

  return (
    <div className={`card border-radius-20 card-min-height h-100 bg-white ${styles.border}`}>
      <div className={classNames([styles.header, headerClassname])}>
        <div className={`${styles.title} d-flex align-items-center`}>
          {
            user.role == "admin" || (canEdit && userData && (userData.id == userId ) && user && (user.role == "neurologist" || user.role.includes("therapist")) )
              ?
              <i className="fas fa-times cursor-pointer px-2" onClick={onDelete}/>
              :
              ""
          }
          <span className="flex-fill">{name || "N/A"}</span>
        </div>
      </div>
      <div className={classNames([styles.body, styles.minHeight])}>
        {children}
      </div>
    </div>
  );
};

export default UploadCard;
